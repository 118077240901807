import { BaseModel, Organization, Project, Role, Site, Account } from "src/app/shared/models";
import * as moment from 'moment';

export class Invitation extends BaseModel {

  // for CASL subject detection on code minification
  static get modelName() { return 'Invitation'; }

  /* tslint:disable */
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  role_code: string;
  accepts_invite: boolean;
  needs_password: boolean;
  sso_flag: boolean;
  accepted_at: moment.Moment;
  status: string;

  // relations
  role: Role;
  invitee: Account;
  inviter: Account;
  site: Site;
  project: Project;
  organization: Organization;

  project_id: string;
  site_id: string;
  organization_id: string;

  /* tslint:enable */

  /**
   * Workaround to initialize default values
   */
  init() {

  }

  get relations() {
    return {
      role: Role,
      invitee: Account,
      inviter: Account,
      site: Site,
      project: Project,
      organization: Organization,
    };
  }

  /**
   * Processes json obj and sets respective properties.
   * @param jsonObj json from api
   */
  parseJSONData(jsonObj: any) {
    super.parseJSONData(jsonObj);

    // cast
    this.accepted_at = this.accepted_at ? moment(this.accepted_at) : null;
    this.project_id = this.project?.id || this.project_id;
    this.site_id = this.site?.id || this.site_id;
    this.organization_id = this.organization?.id || this.organization_id;
  }

  /**
   * Converts this model to POST payload json object
   * @return {object}
   */
  toPayload() {
    const payload: any = {
      id: this.id || null,
      first_name: this.first_name,
      last_name: this.last_name,
      sso_flag: this.sso_flag,
      email: this.email,
      phone: this.phone,
    };

    return payload;
  }
}

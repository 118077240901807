import {Injectable} from '@angular/core';
import Extent from '@arcgis/core/geometry/Extent';
import {BehaviorSubject, Subject} from 'rxjs';
import {DailyReport, Issue, ReportBidItem, ReportMaterialDelivery} from '../models';
import * as moment from 'moment';
import {Parser} from 'expr-eval';
import Polygon from '@arcgis/core/geometry/Polygon';
import Polyline from '@arcgis/core/geometry/Polyline';
import Point from '@arcgis/core/geometry/Point';
import Multipoint from '@arcgis/core/geometry/Multipoint';
import Graphic from '@arcgis/core/Graphic';
import {BidService} from './bid.service';
import { IssueDetailsWrapperComponent } from 'src/app/modules/projects/pages/site-details-page/tab-screens/daily-report/daily-report-components/detail-components/issue-details/issue-details-wrapper/issue-details-wrapper.component';
import { CommonService } from './common.service';
import {
  BidItemDetailsWrapperComponent
} from '../../modules/projects/pages/site-details-page/tab-screens/daily-report/daily-report-components/detail-components/bid-item-details/bid-item-details-wrapper/bid-item-details-wrapper.component';
import {
  DailyReportService
} from '../../modules/projects/pages/site-details-page/tab-screens/daily-report/daily-report.service';
import {ProjectsService} from '../../modules/projects/core/projects.service';
import {
  ActivityItemDetailsComponent
} from '../../modules/projects/pages/site-details-page/tab-screens/daily-report/daily-report-components/detail-components/activitiy-details/activity-item-details/activity-item-details.component';
import { ProjectMapSidenavComponent } from 'src/app/modules/project/project-map/components/project-map-sidenav/project-map-sidenav.component';

@Injectable({
  providedIn: 'root'
})
export class MapService {
  headingsList: BehaviorSubject<any> = new BehaviorSubject<any>([]);

  showWrapperButtons: BehaviorSubject<any> = new BehaviorSubject<any>(true);

  seeMore: Subject<any> = new Subject();
  selectedMarkerData: Subject<any> = new Subject();
  selectedPicturesData: Subject<any> = new Subject();
  closePicturesPanel: Subject<any> = new Subject();

  initiateSiteLocationsMap: Subject<any> = new Subject();

  closeMap: Subject<any> = new Subject();
  closeSidenav: Subject<any> = new Subject();
  closeFilters: Subject<any> = new Subject();

  triggerFullScreenToggle: Subject<any> = new Subject();


  constructor(
    private bidService: BidService,
    private dailyReportService: DailyReportService,
    private projectsService: ProjectsService,
    private commonService: CommonService
  ) {
  }

  removeAllMarkers(map) {
    map?.mapSiteMarkersGraphicsLayer.removeAll();
    map?.mapStationMarkersGraphicsLayer.removeAll();
  }

  setExtent(map, points, expansionFactor = 1.15) {
    if (points.length) {
      const e = points
        .map(g => g.geometry)
        .reduce(
          (acc, geom) => {
            return {
              xmin: Math.min(acc.xmin, geom.longitude),
              ymin: Math.min(acc.ymin, geom.latitude),
              xmax: Math.max(acc.xmax, geom.longitude),
              ymax: Math.max(acc.ymax, geom.latitude)
            };
          },
          {
            xmin: Number.MAX_SAFE_INTEGER,
            ymin: Number.MAX_SAFE_INTEGER,
            xmax: Number.MIN_SAFE_INTEGER,
            ymax: Number.MIN_SAFE_INTEGER
          }
        );

      setTimeout(() => {
        if (e && !isNaN(e.xmax) && !isNaN(e.xmin) && !isNaN(e.ymax) && !isNaN(e.ymin)) {
          if (map && map.mapViewInstance && e.xmin !== e.xmax && e.ymin !== e.ymax) {
            try {
              map.mapViewInstance.extent = new Extent(e).expand(expansionFactor);
            } catch (e) {
              console.warn(e.message);
            }
          } else {
            map.mapViewInstance.center = points[0].geometry;
            map.mapViewInstance.zoom = 16;
          }
        }
      }, 500);

    }
  }

  createMarker(location, data, type) {
    return {
      geometry: {
        type: 'point',
        longitude: location.longitude,
        latitude: location.latitude
      },
      symbol: type,
      data
    };
  }

  createSiteAlignments(site) {
    const alignmentsList = [];
    site?.headings?.forEach((o, i) => {
      const alignments = [];
      o.stations.forEach(s => {
        if (s?.properties?.Longitude && s?.properties?.Latitude) {
          const location = {
            latitude: s.properties.Latitude,
            longitude: s.properties.Longitude
          };
          alignments.push(this.createMarker(location, s, 'alignment'));
        } else {
          console.log(s);
        }
      });
      alignmentsList.push(alignments);
    });

    return alignmentsList;
  }

  drawPolygon(map, site, type, options = {}) {
    const config = {
      geoJson: site.geometry?.coordinates,
      category: type,
      isLine: false,
      isDashed: false,
      ...options
    };

    map?.drawPolygon(config);

    return site?.geometry?.coordinates?.map(o => {
      const location = {
        latitude: o[0],
        longitude: o[1]
      };

      this.createMarker(location, null, 'polygonMarker');
    });
  }

  drawAlignments(map, dataLevels, path = []) {
    try {
      path.forEach(alignment => {
        if (dataLevels.length > 1) {
          alignment = alignment.map(o => {
            o.data.level = 2;
            return o;
          });
        } else {
          alignment = alignment.map(o => {
            o.data.level = 1;
            return o;
          });
        }
        map?.drawProjectSiteMarkers(alignment);
      });
    } catch (e) {
      console.log(e);
    }
  }

  private _createLevelOneGhostLocations(element, details, type) {
    return {
      ...element,
      ...details,
      markerType: type,
      level: 1
    };
  }

  private _createMarker(location, data, type) {
    return {
      geometry: {
        type: 'point',
        longitude: location.longitude,
        latitude: location.latitude
      },
      symbol: type,
      data
    };
  }

  createLevelOneMapData(dailyReports: DailyReport[] = []) {
    let markersList = [];

    let ghostLocations = [];

    dailyReports.forEach(dr => {
      const details = {
        projectId: dr.project_id,
        siteId: dr.site_id,
        reportId: dr.id
      };

      // Bid Item Markers
      const bidItemDetails = this._createLevelOneBidItemMarkers(dr.bid_items, ghostLocations, details);
      const {bidItemMarkers} = bidItemDetails;
      ghostLocations = bidItemDetails.ghostLocations;

      // Material Delivery Markers
      const materialDeliveryDetails = this._createLevelOneMaterialDeliveryMarkers(
        dr.material_deliveries,
        ghostLocations,
        details
      );
      const {materialDeliveryMarkers} = materialDeliveryDetails;
      ghostLocations = materialDeliveryDetails.ghostLocations;

      // Issue Markers
      const issuesDetails = this._createLevelOneIssuesMarkers(dr.issues, ghostLocations, details, dr.report_date);
      const {issueMarkers} = issuesDetails;
      ghostLocations = issuesDetails.ghostLocations;

      // Activity Details
      const activityDetails = this._createLevelOneActivityMarkers(dr.activities, ghostLocations, details);
      const {activityMarkers} = activityDetails;
      ghostLocations = activityDetails.ghostLocations;

      // Combined marker for labor and Equipment
      const laborEquipmentDetails = this._createLevelOneLaborEquipmentMarkers(
        {
          labor: dr.labor,
          equipment: dr.equipment
        },
        ghostLocations,
        details
      );
      const {laborEquipmentMarkers} = laborEquipmentDetails;
      ghostLocations = laborEquipmentDetails.ghostLocations;

      // Test Markers
      const testsDetails = this._createLevelOneTestsMarkers(
        {
          internal: dr.internal_tests,
          external: dr.external_tests
        },
        ghostLocations,
        details
      );
      const {testMarkers} = testsDetails;
      ghostLocations = testsDetails.ghostLocations;

      markersList = [
        ...markersList,
        ...bidItemMarkers,
        ...materialDeliveryMarkers,
        ...issueMarkers,
        ...activityMarkers,
        ...laborEquipmentMarkers,
        ...testMarkers
      ];

      markersList = this.getGroupedMarkersInfo(markersList, 1);
    });

    return {markersList, ghostLocations};
  }

  private _createLevelOneBidItemMarkers(bidItems: ReportBidItem[] = [], ghostLocations: any[] = [], details: any) {
    let bidItemMarkers = [];

    let stationWiseBidItems: any = bidItems.filter(o => !!o.bid_item.record_by_station);
    let multilineBidItems: any = bidItems.filter(o => !o.bid_item.record_by_station);

    if (stationWiseBidItems?.length) {
      stationWiseBidItems = stationWiseBidItems?.reduce((rv, x) => {
        if (x.heading) {
          (rv[`${x.bid_item.item}_${x.heading.id}`] = rv[`${x.bid_item.item}_${x.heading.id}`] || []).push(x);
        } else {
          (rv[x.bid_item.item] = rv[x.bid_item.item] || []).push(x);
        }
        return rv;
      }, {});
    }

    if (multilineBidItems?.length) {
      multilineBidItems = multilineBidItems?.reduce((rv, x) => {
        if (x.bid_item.item) {
          (rv[`${x.bid_item.item}`] = rv[`${x.bid_item.item}`] || []).push(x);
        } else {
          (rv[x.bid_item.item] = rv[x.bid_item.item] || []).push(x);
        }
        return rv;
      }, {});
    }


    stationWiseBidItems = Object.values(stationWiseBidItems);
    multilineBidItems = Object.values(multilineBidItems);

    for (let i = 0; i < stationWiseBidItems.length; i++) {
      const bidItemList = stationWiseBidItems[i];
      const headerItem = bidItemList.filter(j => j.bid_item.record_by_station && !j.station)[0] || {bid_item: bidItemList[0].bid_item};

      const temp: any = this._createBidItemMarkersInLevelOne(bidItemList, headerItem, details);
      const markers = temp.bidItemMarkers;
      const ghosts = temp.ghostLocations;

      bidItemMarkers = [...bidItemMarkers, ...markers];
      ghostLocations = [...ghostLocations, ...ghosts];
    }

    for (let i = 0; i < multilineBidItems.length; i++) {
      const bidItemList = multilineBidItems[i];

      const headerItem = bidItemList[0];

      const temp: any = this._createBidItemMultiLineMarkersInLevelOne(bidItemList, headerItem, details);
      const markers = temp.bidItemMarkers;
      const ghosts = temp.ghostLocations;

      bidItemMarkers = [...bidItemMarkers, ...markers];
      ghostLocations = [...ghostLocations, ...ghosts];
    }

    return {bidItemMarkers, ghostLocations};
  }

  private _createBidItemMarkersInLevelOne(bidItemList, headerItem, details) {
    const bidItemMarkers = [];
    const ghostLocations = [];
    console.log(headerItem);
    let found = false;
    for (let j = 0; j <= bidItemList.length; j++) {
      const eachBidItem = bidItemList[j];

      const data = {
        ...(bidItemList.length > 1 ? {children: bidItemList} : {}),
        ...headerItem,
        ...details,
        level: 1
      };

      if (eachBidItem?.latitude && eachBidItem?.longitude) {
        const location = {
          latitude: eachBidItem.latitude,
          longitude: eachBidItem.longitude
        };

        bidItemMarkers.push(this._createMarker(location, data, 'bid_items'));

        found = true;
        break;
      }

      if (eachBidItem?.station?.properties?.Latitude && eachBidItem?.station?.properties?.Longitude) {
        const location = {
          latitude: eachBidItem?.station?.properties?.Latitude,
          longitude: eachBidItem?.station?.properties?.Longitude
        };

        bidItemMarkers.push(this._createMarker(location, data, 'bid_items'));

        found = true;
        break;
      }

      const pictures = eachBidItem?.pictures || [];

      if (pictures.length) {
        // tslint:disable-next-line:prefer-for-of
        for (let k = 0; k < pictures.length; k++) {
          const eachPicture = pictures[k];
          if (eachPicture?.latitude && eachPicture?.longitude) {
            const location = {
              latitude: eachPicture.latitude,
              longitude: eachPicture.longitude
            };

            bidItemMarkers.push(this._createMarker(location, data, 'bid_items'));

            found = true;
            break;
          }
        }
      }
    }

    if (!found) {
      ghostLocations.push(
        this._createLevelOneGhostLocations(
          {
            ...headerItem,
            ...(bidItemList.length > 1 ? {children: bidItemList} : {}),
            location: false
          },
          details,
          'bid_items'
        )
      );
    }

    return {bidItemMarkers, ghostLocations};
  }
  private _createBidItemMultiLineMarkersInLevelOne(bidItemList, headerItem, details) {
    const bidItemMarkers = [];
    const ghostLocations = [];
    let locationFoundInChildren = false;  // Track if any child has a location

    const data = {
        ...(bidItemList.length > 1 ? {children: bidItemList} : {}),
        ...headerItem,
        ...details,
        level: 1
    };

    // Iterate over each child item in bidItemList
    for (let j = 0; j < bidItemList.length; j++) {
        const eachBidItem = bidItemList[j];
        let itemFound = false;

        // Check direct latitude and longitude
        if (eachBidItem?.latitude && eachBidItem?.longitude) {
            const location = {
                latitude: eachBidItem.latitude,
                longitude: eachBidItem.longitude
            };

            bidItemMarkers.push(this._createMarker(location, {...data, child: eachBidItem}, 'bid_items'));
            locationFoundInChildren = true;
            itemFound = true;
        }

        // Check station latitude and longitude if no direct location is found
        if (!itemFound && eachBidItem?.station?.properties?.Latitude && eachBidItem?.station?.properties?.Longitude) {
            const location = {
                latitude: eachBidItem.station.properties.Latitude,
                longitude: eachBidItem.station.properties.Longitude
            };

            bidItemMarkers.push(this._createMarker(location, {...data, child: eachBidItem}, 'bid_items'));
            locationFoundInChildren = true;
            itemFound = true;
        }

        // Check pictures if no location from previous checks
        const pictures = eachBidItem?.pictures || [];
        for (let k = 0; k < pictures.length && !itemFound; k++) {
            const eachPicture = pictures[k];
            if (eachPicture?.latitude && eachPicture?.longitude) {
                const location = {
                    latitude: eachPicture.latitude,
                    longitude: eachPicture.longitude
                };

                bidItemMarkers.push(this._createMarker(location, {...data, child: eachBidItem}, 'bid_items'));
                locationFoundInChildren = true;
                itemFound = true;
            }
        }
    }

    // If no location was found for any child item, add a single ghost location for the entire bidItemList
    if (!locationFoundInChildren) {
        ghostLocations.push(
            this._createLevelOneGhostLocations(
                {
                    ...headerItem,
                    ...(bidItemList.length > 1 ? {children: bidItemList} : {}),
                    location: false
                },
                details,
                'bid_items'
            )
        );
    }

    return {bidItemMarkers, ghostLocations};
}

  private _createLevelOneMaterialDeliveryMarkers(
    materialDeliveries: ReportMaterialDelivery[] = [],
    ghostLocations: any[] = [],
    details: any
  ) {
    const materialDeliveryMarkers = [];
    materialDeliveries.forEach(element => {
      if (element.latitude && element.longitude) {
        const data = {
          ...element,
          ...details,
          level: 1
        };

        const location = {
          latitude: element.latitude,
          longitude: element.longitude
        };

        materialDeliveryMarkers.push(this._createMarker(location, data, 'deliveries'));
      } else {
        ghostLocations.push(this._createLevelOneGhostLocations(element, details, 'deliveries'));
      }
    });

    return {materialDeliveryMarkers, ghostLocations};
  }

  private _createLevelOneIssuesMarkers(issues: Issue[] = [], ghostLocations: any[] = [], details: any, reportDate?: any) {
    const issueMarkers = [];

    issues = issues.filter(o => {
      const createdAtMatch = o.created_at && moment(o.created_at).isSame(moment(reportDate, 'YYYY-MM-DD'), 'day');
      const updatedAtMatch = o.updated_at && moment(o.updated_at).isSame(moment(reportDate, 'YYYY-MM-DD'), 'day');
      return createdAtMatch || updatedAtMatch;
    }); 

    issues.forEach(issue => {
      if (issue.latitude && issue.longitude) {
        const data = {
          ...issue,
          ...details,
          level: 1
        };

        const location = {
          latitude: issue.latitude,
          longitude: issue.longitude
        };

        issueMarkers.push(this._createMarker(location, data, 'issues'));
      } else {
        ghostLocations.push(this._createLevelOneGhostLocations(issue, details, 'issues'));
      }
    });

    return {issueMarkers, ghostLocations};
  }

  private _createLevelOneActivityMarkers(activities: any[] = [], ghostLocations: any[] = [], details: any) {
    const activityMarkers = [];

    let found = false;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < activities.length; i++) {
      const activity = activities[i];
      if (activity.latitude && activity.longitude) {
        const data = {
          ...activity,
          ...details,
          level: 1
        };

        const location = {
          latitude: activity.latitude,
          longitude: activity.longitude
        };

        activityMarkers.push(this._createMarker(location, data, 'activities'));
        found = true;
      } else {
        const {pictures} = activity;
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < pictures.length; j++) {
          const eachPicture = pictures[j];
          if (pictures[j].latitude && pictures[j].longitude) {
            const data = {
              ...activity,
              ...details,
              level: 1
            };

            const location = {
              latitude: eachPicture.latitude,
              longitude: eachPicture.longitude
            };

            activityMarkers.push(this._createMarker(location, data, 'activities'));
            found = true;
          }
        }

        if (!found) {
          ghostLocations.push(this._createLevelOneGhostLocations(activity, details, 'activities'));
        }
      }
    }

    return {activityMarkers, ghostLocations};
  }

  private _createLevelOneLaborEquipmentMarkers(
    laborEquipments: { labor: any; equipment: any },
    ghostLocations: any[] = [],
    details: any
  ) {
    const {labor = [], equipment = []} = laborEquipments;

    const list = [...labor, ...equipment];

    const popupData = {
      employee: 0,
      subcontractor: 0,
      employeeStandardTime: 0,
      employeeOverTime: 0,
      machine: equipment.length,
      machineStandardTime: 0,
      machineOverTime: 0
    };

    const laborEquipmentMarkers = [];

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < list.length; i++) {
      const eachLabor = list[i];
      if (eachLabor.latitude && eachLabor.longitude) {
        laborEquipmentMarkers.push({
          geometry: {
            type: 'point',
            longitude: eachLabor.longitude,
            latitude: eachLabor.latitude
          },
          symbol: 'labor_equipment'
        });
        break;
      }
    }

    labor.forEach(eachLabor => {
      if (eachLabor.sub_contractor) {
        popupData.subcontractor = popupData.subcontractor + 1;
      } else {
        popupData.employee = popupData.employee + 1;
      }

      if (eachLabor.standard_time) {
        popupData.employeeStandardTime = popupData.employeeStandardTime + eachLabor.standard_time;
      }

      if (eachLabor.over_time) {
        popupData.employeeOverTime = popupData.employeeOverTime + eachLabor.over_time;
      }
    });

    popupData.machine = equipment.length;

    equipment.forEach(eachEquipment => {
      if (eachEquipment.standard_time) {
        popupData.machineStandardTime = popupData.machineStandardTime + eachEquipment.standard_time;
      }

      if (eachEquipment.over_time) {
        popupData.machineOverTime = popupData.machineOverTime + eachEquipment.over_time;
      }
    });

    if (list.length && !laborEquipmentMarkers.length) {
      ghostLocations.push(
        this._createLevelOneGhostLocations(
          {
            ...laborEquipments,
            ...popupData
          },
          details,
          'labor_equipment'
        )
      );
    }

    if (laborEquipmentMarkers.length) {
      laborEquipmentMarkers[0].data = {
        ...laborEquipments,
        ...popupData,
        ...details,
        level: 1
      };
    }

    return {laborEquipmentMarkers, ghostLocations};
  }

  private _createLevelOneTestsMarkers(tests: any, ghostLocations: any[], details: any) {
    const testMarkers = [];

    tests.internal?.forEach(o => (o.test_type = 'internal'));
    tests.external?.forEach(o => (o.test_type = 'external'));

    const internalTestObj = tests?.internal?.reduce((rv, x) => {
      if (rv[x.internal_test_id]) {
        rv[x.internal_test_id].push({
          ...x,
          test_type: 'internal',
          type: 'quality_test'
        });
      } else {
        rv[x.internal_test_id] = [
          {
            ...x,
            test_type: 'internal',
            type: 'quality_test'
          }
        ];
      }
      return rv;
    }, {});

    const externalTestObj = tests?.external?.reduce((rv, x) => {
      if (rv[x.bid_item.item]) {
        rv[x.bid_item.item].push({
          ...x,
          test_type: 'external',
          type: 'quality_test'
        });
      } else {
        rv[x.bid_item.item] = [
          {
            ...x,
            test_type: 'external',
            type: 'quality_test'
          }
        ];
      }
      return rv;
    }, {});

    const testList: any[] = [
      ...(internalTestObj ? Object.values(internalTestObj) : []),
      ...(externalTestObj ? Object.values(externalTestObj) : [])
    ];

    let found = false;
    // tslint:disable-next-line:prefer-for-of
    for (let o = 0; o < testList.length; o++) {
      for (let i = 0; i <= testList[o].length; i++) {
        const eachTestItem = testList[o][i];
        const data = {
          ...(testList[o].length > 1 ? {children: testList[o]} : {}),
          ...eachTestItem,
          ...details,
          level: 1
        };
        if (eachTestItem?.latitude && eachTestItem?.longitude) {
          const location = {
            longitude: eachTestItem.longitude,
            latitude: eachTestItem.latitude
          };

          testMarkers.push(this._createMarker(location, data, 'quality_test'));
          found = true;
          if (found) {
            break;
          }
        } else {
          const pictures = eachTestItem?.pictures || [];

          if (pictures.length) {
            // tslint:disable-next-line:prefer-for-of
            for (let j = 0; j < pictures.length; j++) {
              const eachPicture = pictures[j];
              if (eachPicture?.latitude && eachPicture?.longitude) {
                const location = {
                  longitude: eachPicture.longitude,
                  latitude: eachPicture.latitude
                };

                testMarkers.push(this._createMarker(location, data, 'quality_test'));
                found = true;
                if (found) {
                  break;
                }
              }
            }
          } else {
            found = false;
          }

          if (!found) {
            ghostLocations.push(
              this._createLevelOneGhostLocations(
                {
                  ...(testList[o].length > 1 ? {children: testList[o]} : {}),
                  ...eachTestItem
                },
                details,
                'quality_test'
              )
            );

            break;
          }
        }
      }
    }

    return {testMarkers, ghostLocations};
  }

  /****************************************************/
  /*************** HANDLE MARKER POPUP ****************/

  /****************************************************/

  levelOneBidItemPopupData($event: any, isGhost = false) {
    let isMultilineEnable = false;
    let category = $event?.graphic?.attributes?.category;
    let markerData = $event?.graphic?.attributes?.data;
    // if($event?.graphic?.attributes?.data.child !=undefined ){
    //   markerData = $event?.graphic?.attributes?.data.child;
    // }
    let geometry = $event?.graphic?.geometry;
    let screenPoint = $event?.screenPoint;

    if (isGhost) {
      category = $event.markerType;
      markerData = $event;
      geometry = null;
      screenPoint = null;
    }
    let stations =undefined;
    if($event?.graphic?.attributes?.data.child === undefined && (isGhost && markerData.child === undefined) ){
      stations = markerData.children?.filter(o => o.station);
    }
    else{
      isMultilineEnable = true;
    }
    console.log(markerData);
    const quantity = this._getQuantity({
      bidItem: isMultilineEnable?markerData.child.bid_item:markerData.bid_item,
      stations: (stations && stations.length > 0) ? stations : [isMultilineEnable?markerData.child:markerData],
      headerItem: isMultilineEnable?markerData?.child:markerData
    });

    return [
      {
        type: category,
        title: 'BID ITEM',
        heading: markerData?.bid_item?.item,
        subheading1: markerData?.bid_item?.description,
        ...(stations && stations.length
          ? {
            subheading2: `Stations from ${stations[0].station.name} to ${stations[stations.length - 1].station.name}`
          }
          : {}),
        summary: {
          left: {
            label: 'QUANTITY',
            value: (() => {
              try {
                let value = (quantity / (isMultilineEnable?markerData?.child?.bid_item?.quantity:markerData?.bid_item?.quantity)) * 100;

                if (!value) {
                  value = 0;
                } else if (value > 100) {
                  value = 100;
                }

                value = Number(value.toFixed(2));

                return value;
              } catch (e) {
                return 0;
              }
            })(),
            statistics: (() => {
              const numerator = `${Number(quantity?.toFixed(2))}${isMultilineEnable?markerData?.child?.bid_item?.uom:markerData?.bid_item?.uom}`;
              const denominator = `${Number(markerData?.bid_item?.quantity?.toFixed(2))}${isMultilineEnable?markerData?.child?.bid_item?.uom:markerData?.bid_item?.uom}`;

              return `${numerator} / ${denominator}`;
            })()
          }
        },
        description: isMultilineEnable?markerData?.child?.comment:markerData?.comment,
        more_link_text: 'See More',
        data: {
          markerData: {
            ...markerData,
            markerCategory: category
          },
          type: category,
          geometry,
          screenPoint
        }
      }
    ];
  }

  levelOneDeliveriesPopupData($event: any, isGhost = false) {
    let category = $event?.graphic?.attributes?.category;
    let markerData = $event?.graphic?.attributes?.data;
    let geometry = $event?.graphic?.geometry;
    let screenPoint = $event?.screenPoint;

    if (isGhost) {
      category = $event.markerType;
      markerData = $event;
      geometry = null;
      screenPoint = null;
    }

    return [
      {
        type: category,
        title: 'DELIVERY & USAGE',
        heading: markerData?.material?.name || markerData?.bid_item?.name,
        subheading1: null,
        summary: {
          left: {
            label: 'TODAY',
            value: (() => {
              try {
                const {received, used} = markerData;

                if (markerData.material && received && used) {
                  let value = (used / received) * 100;

                  if (!value) {
                    value = 0;
                  } else if (value > 100) {
                    value = 100;
                  }

                  value = Number(value.toFixed(2));

                  return value;
                } else if (received && !used) {
                  return 0;
                }

                return 100;
              } catch (e) {
                return 100;
              }
            })(),
            statistics: (() => {
              try {
                let value = '';
                if (markerData.material) {
                  let {received, used} = markerData;

                  received = (received && Number(received.toFixed(2))) || 0;
                  used = (used && Number(used.toFixed(2))) || 0;

                  value = `Received: ${received} / Used: ${used}`;
                } else {
                  value = `Received: ${markerData.quantity}`;
                }

                return value;
              } catch (e) {
                return 'Received: 0 / Used: 0';
              }
            })()
          },
          ...(markerData?.material
            ? {
              right: {
                label: 'ALL TIME',
                value: (() => {
                  const received = markerData?.material?.total_received;
                  const used = markerData?.material?.total_used;

                  try {
                    if (received && used) {
                      let value = (used / received) * 100;
                      if (!value) {
                        value = 0;
                      } else if (value > 100) {
                        value = 100;
                      }

                      value = Number(value.toFixed(2));

                      return value;
                    }

                    return 0;
                  } catch (e) {
                    return 0;
                  }
                })(),
                statistics: (() => {
                  let received = markerData?.material?.total_received;
                  let used = markerData?.material?.total_used;

                  received = (received && Number(received.toFixed(2))) || 0;
                  used = (used && Number(used.toFixed(2))) || 0;

                  return `Received: ${received} / Used: ${used}`;
                })()
              }
            }
            : {})
        },
        description: markerData?.comment,
        more_link_text: 'See More',
        data: {
          markerData: {
            ...markerData,
            markerCategory: category
          },
          type: category,
          geometry,
          screenPoint
        }
      }
    ];
  }

  levelOneActivitiesPopupData($event: any, isGhost = false) {
    let category = $event?.graphic?.attributes?.category;
    let markerData = $event?.graphic?.attributes?.data;
    let geometry = $event?.graphic?.geometry;
    let screenPoint = $event?.screenPoint;

    if (isGhost) {
      category = $event.markerType;
      markerData = $event;
      geometry = null;
      screenPoint = null;
    }

    return [
      {
        type: category,
        title: 'Activities',
        heading: markerData?.title,
        subheading1: `${markerData.pictures?.length || 0} pictures${
          markerData.annotations?.length ? `, ${markerData.annotations?.length} annotation(s)` : ''
        }`,
        more_link_text: 'See More',
        data: {
          markerData: {
            ...markerData,
            markerCategory: category
          },
          type: category,
          geometry,
          screenPoint
        }
      }
    ];
  }

  levelOneQualityPopupData($event: any, isGhost = false) {
    let category = $event?.graphic?.attributes?.category;
    let markerData = $event?.graphic?.attributes?.data;
    let geometry = $event?.graphic?.geometry;
    let screenPoint = $event?.screenPoint;

    if (isGhost) {
      category = $event.markerType;
      markerData = $event;
      geometry = null;
      screenPoint = null;
    }

    const summary = {
      type: null,
      typeClass: null,
      icon: null,
      status: null,
      statusClass: null,
      date: null
    };

    switch (markerData.status) {
      case 'passed':
        summary.type = 'Closed';
        summary.typeClass = 'closed';
        summary.icon = null;
        summary.status = 'Passed';
        summary.statusClass = 'passed';
        summary.date = `(${moment(markerData.updated_at)
          .format('MMMM dd, yyyy')})`;
        break;
      case 'failed':
        summary.type = 'Closed';
        summary.typeClass = 'closed';
        summary.icon = null;
        summary.status = 'Failed';
        summary.statusClass = 'failed';
        summary.date = `(${moment(markerData.updated_at)
          .format('MMMM dd, yyyy')})`;
        break;
      case 'sent_to_lab':
        summary.type = 'Open';
        summary.typeClass = 'open';
        summary.icon = null;
        summary.status = 'Requested';
        summary.statusClass = 'requested';
        break;
      case 'lab_scheduled':
        summary.type = 'Open';
        summary.typeClass = 'open';
        summary.icon = null;
        summary.status = 'Scheduled';
        summary.statusClass = 'scheduled';
        break;
      case 'lab_activity_completed':
        summary.type = 'Open';
        summary.typeClass = 'open';
        summary.icon = null;
        summary.status = 'Visit completed';
        summary.statusClass = 'visit-completed';
        break;
      case 'updated_report':
        summary.type = 'Open';
        summary.typeClass = 'open';
        summary.icon = null;
        summary.status = 'Results uploaded';
        summary.statusClass = 'results-uploaded';
        break;
    }

    return [
      {
        type: category,
        title: 'MATERIAL TESTING',
        heading: markerData.test_type === 'internal' ? markerData.internal_test?.name : markerData?.bid_item?.item,
        subheading1:
          markerData.test_type === 'internal' ? markerData?.bid_item?.item : markerData?.bid_item?.description,
        ...(markerData.test_type !== 'internal'
          ? {summary}
          : {}),
        description: markerData.comment,
        more_link_text: 'See More',
        data: {
          markerData: {
            ...markerData,
            markerCategory: category
          },
          type: category,
          geometry,
          screenPoint
        }
      }
    ];
  }

  levelOneIssuesPopupData($event: any, isGhost = false) {
    let category = $event?.graphic?.attributes?.category;
    let markerData = $event?.graphic?.attributes?.data;
    let geometry = $event?.graphic?.geometry;
    let screenPoint = $event?.screenPoint;

    if (isGhost) {
      category = $event.markerType;
      markerData = $event;
      geometry = null;
      screenPoint = null;
    }

    return [
      {
        type: category,
        title: 'ISSUES',
        heading: markerData?.title,
        subheading1: markerData?.type,
        summary: {
          type: markerData?.status,
          typeClass: markerData?.status,
          date:
            markerData?.status === 'open'
              ? moment(markerData.created_at).format('MMMM DD, YYYY')
              : moment(markerData?.closed_at).format('MMMM DD, YYYY')
        },
        description: markerData?.description,
        more_link_text: 'See More',
        data: {
          markerData: {
            ...markerData,
            markerCategory: category
          },
          type: category,
          geometry,
          screenPoint
        }
      }
    ];
  }

  levelOneLaborEquipmentPopupData($event: any, isGhost = false) {
    let category = $event?.graphic?.attributes?.category;
    let popupData = $event?.graphic?.attributes?.data;
    let geometry = $event?.graphic?.geometry;
    let screenPoint = $event?.screenPoint;

    if (isGhost) {
      category = $event.markerType;
      popupData = $event;
      geometry = null;
      screenPoint = null;
    }
    return [
      {
        type: category,
        title: 'LABOR & EQUIPMENT',
        stacks: [
          {
            heading: `${popupData.employee} Employee(s), ${popupData.subcontractor} Subcontractor(s)`,
            subheading1: `${popupData.employeeStandardTime} hour(s), ${popupData.employeeOverTime} hour(s) overtime`,
            description: popupData.labor.find(o => o.is_header === true)?.comment || null
          },
          {
            heading: `${popupData.machine} Machine(s)`,
            subheading1: `${popupData.machineStandardTime} hour(s), ${popupData.machineOverTime} hour(s) overtime`,
            description: popupData.equipment.find(o => o.is_header === true)?.comment || null
          }
        ],
        more_link_text: 'See More',
        data: {
          markerData: {
            ...popupData,
            markerCategory: category
          },
          type: category,
          geometry,
          screenPoint
        }
      }
    ];
  }

  levelOneGhostLocationsPopupData(ghostLocations) {
    let popupData = [];

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < ghostLocations.length; i++) {
      const eachLocation = ghostLocations[i];
      const category = eachLocation.markerType;

      switch (category) {
        case 'bid_items':
          popupData = [...popupData, ...this.levelOneBidItemPopupData(eachLocation, true)];
          break;
        case 'deliveries':
          popupData = [...popupData, ...this.levelOneDeliveriesPopupData(eachLocation, true)];
          break;
        case 'activities':
          popupData = [...popupData, ...this.levelOneActivitiesPopupData(eachLocation, true)];
          break;
        case 'quality_test':
          popupData = [...popupData, ...this.levelOneQualityPopupData(eachLocation, true)];
          break;
        case 'issues':
          popupData = [...popupData, ...this.levelOneIssuesPopupData(eachLocation, true)];
          break;
        case 'labor_equipment':
          popupData = [...popupData, ...this.levelOneLaborEquipmentPopupData(eachLocation, true)];
          break;
      }
    }

    return popupData;
  }

  levelOneGroupedLocationsPopupData($event: any) {
    const category = $event?.graphic?.attributes?.category;
    const children = $event?.graphic?.attributes?.data?.children;
    const geometry = $event?.graphic?.geometry;
    const screenPoint = $event?.screenPoint;

    return this.levelOneGhostLocationsPopupData(children);
  }

  // ********************************************************* //
  // *************      ZOOM LEVEL 2       ******************* //
  // ********************************************************* //

  createLevelTwoData = params => {
    const {children, type, ...details} = params;
    let combinedChildren = [];

    const {equipment, labor} = params;
    if (equipment && labor) {
      combinedChildren = [...equipment, ...labor];
    }

    let markersList: any;

    if (children || combinedChildren?.length) {
      switch (type) {
        case 'bid_items':
          markersList = this._createLevelTwoBidItemMarkers(children, details);
          break;
        case 'deliveries':
          break;
        case 'activities':
          break;
        case 'quality_test':
          markersList = this._createLevelTwoTestsMarkers(children, details);
          break;
        case 'issues':
          break;
        case 'labor_equipment':
          markersList = this._createLevelTwoLaborEquipmentMarkers(combinedChildren, details);
          break;
        default:
          return {
            markersList: []
          };
      }

      console.log(markersList);

      return {
        markersList: this.getGroupedMarkersInfo(markersList, 2)
      };

    } else {
      return this.createLevelThreeData(params);
    }
  }

  private _createLevelTwoBidItemMarkers(bidItems, details) {
    const markersList = [];

    bidItems.forEach((eachBidItem: any) => {
      const data = {
        ...eachBidItem,
        ...details,
        level: 2
      };

      let location = {
        latitude: null,
        longitude: null
      };

      if (eachBidItem?.station?.properties?.Latitude && eachBidItem?.station?.properties?.Longitude) {
        location = {
          latitude: eachBidItem?.station?.properties?.Latitude,
          longitude: eachBidItem?.station?.properties?.Longitude
        };
      } else if (eachBidItem?.latitude && eachBidItem.longitude) {
        location = {
          latitude: eachBidItem.latitude,
          longitude: eachBidItem.longitude
        };
      } else {
        const {pictures} = eachBidItem;
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < pictures.length; j++) {
          const eachPicture = pictures[j];
          if (eachPicture?.latitude && eachPicture?.longitude) {
            location = {
              latitude: eachPicture.latitude,
              longitude: eachPicture.longitude
            };
            break;
          }
        }
      }

      if (location.latitude && location.longitude) {
        location = {
          latitude: String(location.latitude),
          longitude: String(location.longitude)
        };
        markersList.push(this._createMarker(location, data, 'bid_items'));
      }
    });

    return markersList;
  }

  private _createLevelTwoTestsMarkers(tests, details) {
    const markersList = [];

    tests.forEach((eachTest: any) => {
      if (eachTest?.latitude && eachTest.longitude) {
        const data = {
          ...eachTest,
          ...details,
          level: 2
        };

        markersList.push(this._createMarker(eachTest, data, 'quality_test'));
      } else {
        const {pictures} = eachTest;
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < pictures?.length; j++) {
          const eachPicture = pictures[j];
          if (eachPicture?.latitude && eachPicture?.longitude) {
            const data = {
              ...eachTest,
              ...details,
              level: 2
            };

            const location = {
              latitude: eachPicture.latitude,
              longitude: eachPicture.longitude
            };

            markersList.push(this._createMarker(location, data, 'quality_test'));
            break;
          }
        }
      }
    });

    return markersList;
  }

  private _createLevelTwoLaborEquipmentMarkers(laborEquipment, details) {
    const markersList = [];

    laborEquipment.forEach((eachElement: any) => {
      if (eachElement?.latitude && eachElement.longitude) {
        const data = {
          ...eachElement,
          ...details,
          level: 2
        };

        const location = {
          latitude: eachElement.latitude,
          longitude: eachElement.longitude
        };

        markersList.push(this._createMarker(location, data, 'labor_equipment'));
      } else {
        const {pictures} = eachElement;
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < pictures.length; j++) {
          const eachPicture = pictures[j];
          if (eachPicture?.latitude && eachPicture?.longitude) {
            const data = {
              ...eachElement,
              ...details,
              level: 2
            };

            const location = {
              latitude: eachPicture.latitude,
              longitude: eachPicture.longitude
            };

            markersList.push(this._createMarker(location, data, 'labor_equipment'));
            break;
          }
        }
      }
    });

    return markersList;
  }

  // ********************************************************* //
  // *************      ZOOM LEVEL 3       ******************* //
  // ********************************************************* //

  createLevelThreeData = params => {
    const {element, type, ...details} = params;
    switch (type) {
      case 'bid_items':
      case 'deliveries':
      case 'activities':
      case 'quality_test':
      case 'issues':
        return {
          markersList: this._handleLevelThreeMarkers(element, details, type),
          imagesList: this._handleLevelThreePictures(element),
          noLocatioImageList: this._handleLevelThreePicturesWithoutLocation(element)
        };
      case 'labor_equipment':
        return this._createLevelThreeLaborMarkers(element, details);
      default:
        return {
          markersList: [],
          imagesList: [],
          noLocatioImageList:[]
        };
    }
  }

  private _handleLevelThreePicturesWithoutLocation(element) {
    const noLocatioImageList = [];
  
    // Filter pictures with invalid or missing coordinates
    const noLocationPictures = element.pictures.filter(o => !o.latitude || !o.longitude) || [];
  
    if (noLocationPictures.length > 0) {
      // Group all pictures with no location into a single entry
      noLocatioImageList.push({
        url: noLocationPictures[0].thumb_url, // Use the first image's thumbnail URL as a representative
        rotation: noLocationPictures[0].direction, // Use the first image's direction as a representative
        geometry: null, // No geometry since there's no location
        symbol: 'images',
        data: {
          picturesList: noLocationPictures, // Aggregate all no-location pictures
          count: noLocationPictures.length, // Total count of no-location pictures
          bidItemName: element?.bid_item?.item || null,
          comment: element.comment,
          type: 'image',
          level: 3
        }
      });
    }
  
    return noLocatioImageList || [];
  }

  private _createLevelThreeLaborMarkers(laborEquipment, details) {
    const markersList = [];
    const imagesList = [];
    const noLocatioImageList = [];
    let list = [];
    if (laborEquipment.labor && laborEquipment.equipment) {
      list = [...laborEquipment.labor, ...laborEquipment.equipment];
    } else {
      list = [laborEquipment];
    }

    list.forEach(o => {
      markersList.push(...this._handleLevelThreeMarkers(o, details, 'labor_equipment'));
      imagesList.push(...this._handleLevelThreePictures(o));
      noLocatioImageList.push(...this._handleLevelThreePicturesWithoutLocation(o));
    });

    return {markersList, imagesList, noLocatioImageList};
  }

  private _handleLevelThreeMarkers(element, details, type) {
    if (element?.latitude && element.longitude) {
      const data = {
        ...element,
        ...details,
        level: 3
      };

      const location = {
        longitude: element.longitude,
        latitude: element.latitude
      };

      return [this._createMarker(location, data, type)];
    }

    return [];
  }

  private _handleLevelThreePictures(element) {
    const imagesList = [];

    const pictures = element.pictures.filter(o => o.latitude && o.longitude) || [];

    const picturesObj = pictures?.reduce((rv, x) => {
      (rv[`${x.latitude}_${x.longitude}`] = rv[`${x.latitude}_${x.longitude}`] || []).push(x);
      return rv;
    }, {});

    const pictureValues = Object.values(picturesObj);

    // tslint:disable-next-line:prefer-for-of
    for (let j = 0; j < pictureValues.length; j++) {
      const picturesList = pictureValues[j] || [];

      imagesList.push({
        url: picturesList[0].thumb_url,
        rotation: picturesList[0].direction,
        geometry: {
          type: 'point',
          longitude: picturesList[0].longitude,
          latitude: picturesList[0].latitude
        },
        symbol: 'images',
        data: {
          picturesList,
          count: picturesList['length'],
          bidItemName: element?.bid_item?.item || null,
          comment: element.comment,
          type: 'image',
          level: 3
        }
      });
    }

    return imagesList || [];
  }

  /****************************************************/
  /************** HANDLE ROLLUP QUANTITY **************/
  /****************************************************/

  private _getQuantity(params) {
    console.log(params);
    const {bidItem, stations, headerItem} = params;

    if (!bidItem?.rollup) {
      // If stations[0] has children, sum the quantities of the children; otherwise, use stations[0].quantity
      if (stations[0]?.children?.length > 0) {
        return stations[0].children.reduce((sum, child) => sum + (child?.quantity || 0), 0);
      }
      return stations[0]?.quantity || 0;
    }
    
    if (!bidItem?.record_by_station && bidItem?.rollup) {
      // If stations[0] has children, perform _simpleRollup on each child; otherwise, use stations[0]
      if (stations[0]?.children?.length > 0) {
        const rollupValues = stations[0].children.map(child =>
          this._simpleRollup({
            rollupFormula: bidItem.rollup_formula,
            fields: this._getFields(bidItem.fields, child?.field_values || {}),
          }) || 0
        );
        // Sum up the rollup results
        return rollupValues.reduce((sum, value) => sum + value, 0);
      }
    
      // Default logic for stations[0]
      return this._simpleRollup({
        rollupFormula: bidItem.rollup_formula,
        fields: this._getFields(bidItem.fields, stations[0]?.field_values || {}),
      });
    }
    

    if (bidItem.record_by_station && bidItem.rollup) {
      const headings = this.headingsList.getValue();

      return this.bidService.getRollup(
        {
          ...bidItem,
          headings
        },
        headerItem,
        stations
      );
    }
  }

  private _simpleRollup(params) {
    const {rollupFormula, fields} = params;
    return Number(Parser.evaluate(rollupFormula, fields).toFixed(2));
  }

  private _getFields(fields = [], values = [], isView = false) {
    let fieldValues = [];
    values.map(o => {
      const currentField = fields.find(i => o.id === i.id);

      if (currentField) {
        fieldValues = {
          ...fieldValues,
          [currentField.name]: o.value
        };
      }
    });

    return fieldValues;
  }

  getTooltipContent(data) {
    switch (data.markerCategory) {
      case 'activities':
        return 'Activities';
      case 'bid_items':
        if (data.level >= 2) {
          return `${data.bid_item.item}${data.station ? `- (${data.station?.name})` : ''}`;
        }
        return data.bid_item.item;
      case 'issues':
        return data.bid_item.item;
      case 'alignment':
        return data.name;
      case 'quality_test':
        if (data.test_type === 'internal') {
          return data.internal_test.name || 'Onsite Tests';
        }

        if (data.test_type === 'external') {
          return data.external_test.name || 'Offsite Tests';
        }

        break;
      case 'labor_equipment':
        if (data.labor) {
          return `${data.labor.first_name} ${data.labor.last_name}`;
        }

        if (data.equipment) {
          return `${data.equipment.description ? `${data.equipment.description} - ` : ''}${data.equipment.make}`;
        }

        return 'Labor & Equipment';
      case 'deliveries':
        return data?.material?.name || 'Material Delivery';
      default:
        return;
    }
  }

  getGroupedMarkersInfo(markersList, level) {
    const reduced = markersList?.reduce((rv, x) => {
      (rv[`${x.geometry.latitude}_${x.geometry.longitude}`] =
        rv[`${x.geometry.latitude}_${x.geometry.longitude}`] || []).push(x);
      return rv;
    }, {});

    const individualMarkers = [];
    const groupedMarkers = [];

    Object.values(reduced).forEach((o: any[]) => {
      if (o.length > 1) {
        groupedMarkers.push(o);
      } else {
        individualMarkers.push(...o);
      }
    });

    if (groupedMarkers.length) {
      groupedMarkers.forEach((eachGroupedMarker: any[]) => {
        const location = {
          latitude: eachGroupedMarker[0].geometry.latitude,
          longitude: eachGroupedMarker[0].geometry.longitude
        };
        const data = [];
        eachGroupedMarker.forEach(marker => {
          data.push(this._createLevelOneGhostLocations(marker.data, {}, marker.symbol));
        });

        individualMarkers.push(this._createMarker(location, {level, children: data}, 'grouped_locations'));
      });
    }

    return individualMarkers;
  }

  openSidenavOnMarkerImageClick(data) {
    const paramsForSidenav: any = {
      options: {
        disabled: true,
        showActionsSection: false
      }
    };
    this.commonService.updatelevelThreeClick(true);
    data.isSideNavEnable = true;
    paramsForSidenav.data = data;
    paramsForSidenav.component = ProjectMapSidenavComponent;
    this.projectsService.openRightPanel.next(paramsForSidenav);

  }

  openSidenavOnMarkerClick(data) {
    const paramsForSidenav: any = {
      options: {
        disabled: true,
        showActionsSection: false
      }
    };

    try {
      switch (data.type || data.parent?.type || data.markerCategory) {
        case 'bid_items':
          if (data.parent?.children?.length || data.children?.length) {
            paramsForSidenav.data = this.dailyReportService.formatBidItems(data?.parent?.children || data?.children)[0];
          } else {
            paramsForSidenav.data = this.dailyReportService.formatBidItems([data])[0];
          }

          if(data?.child ?.id){
            paramsForSidenav.data = {
              ...paramsForSidenav.data,
              ...(data?.child?.id ? { clickId: data.child.id } : {})
            };
          }
          else if(data?.id && data.children){
            paramsForSidenav.data = {
              ...paramsForSidenav.data,
              ...(data?.id ? { clickId: data.id } : {})
            };
          }

          paramsForSidenav.component = BidItemDetailsWrapperComponent;
          break;
        case 'activities':
          paramsForSidenav.data = data;

          paramsForSidenav.component = ActivityItemDetailsComponent;
          break;
        default:
          if(data?.markerCategory === "issues"){
            paramsForSidenav.data = data;
            paramsForSidenav.data = {
              ...paramsForSidenav.data,
              ...({ isMarkerClick: true })
            };
            this.commonService.updateIsMarkerClick(true);            
            paramsForSidenav.component = IssueDetailsWrapperComponent;
          }
          break;
      }

    } catch (e) {
      console.log(e);
    }

    console.log(paramsForSidenav)
    if(paramsForSidenav.component != undefined){
    this.projectsService.openRightPanel.next(paramsForSidenav);
    }
  }
  // Draw GeoJSON geometry onto the map
  drawGeoJsonGeometry(mapComponentInstance, geoJsonGeometry) {
    let geometryGraphic = null;

    try {
      if (!mapComponentInstance.mapViewInstance) return;

      // Handle each geometry type
      switch (geoJsonGeometry.type) {
        case 'Polygon':
          geometryGraphic = this.createPolygonGraphic(geoJsonGeometry.coordinates);
          break;
        case 'MultiPolygon':
          geometryGraphic = this.createPolygonGraphic(geoJsonGeometry.coordinates, true);
          break;
        case 'Point':
          geometryGraphic = this.createPointGraphic(geoJsonGeometry.coordinates);
          break;
        case 'MultiPoint':
          geometryGraphic = this.createMultipointGraphic(geoJsonGeometry.coordinates);
          break;
        case 'LineString':
          geometryGraphic = this.createPolylineGraphic(geoJsonGeometry.coordinates);
          break;
        case 'MultiLineString':
          geometryGraphic = this.createPolylineGraphic(geoJsonGeometry.coordinates, true);
          break;
        case 'GeometryCollection':
          geoJsonGeometry.geometries.forEach((geom) => {
            this.drawGeoJsonGeometry(mapComponentInstance.mapViewInstance, geom);
          });
          return;
      }

      // Add the graphic to the map and reorder if needed
      if (geometryGraphic) {  
        mapComponentInstance?.drawGeoGraphicLayer(geometryGraphic);
        mapComponentInstance.mapViewInstance.graphics.refresh();
       // mapComponentInstance.mapViewInstance.graphics.add(geometryGraphic);
        //mapComponentInstance.mapViewInstance.graphics.refresh(); 
       // mapComponentInstance.mapViewInstance.graphics.reorder(geometryGraphic,0);


        return true ;
      }
      return false;
    } catch (error) {
      return false ;
      console.error('Error drawing GeoJSON geometry:', error);
    }
  }

  // Helper methods for creating different graphic types
  private createPolygonGraphic(coordinates, isMultiPolygon = false) {
    const polygon = new Polygon({
      rings: isMultiPolygon ? coordinates[0] : coordinates,
      spatialReference: { wkid: 4326 }
    });
    return new Graphic({
      geometry: polygon,
      symbol: {
        // @ts-ignore
        type: 'simple-fill',
        color: [173, 216, 230, 0.3],
        outline: { color: [0, 0, 255, 0.3], width: 2 }
      }
    });
  }

  private createPointGraphic(coordinates) {
    const point = new Point({
      longitude: coordinates[0],
      latitude: coordinates[1],
      spatialReference: { wkid: 4326 }
    });
    return new Graphic({
      geometry: point,
      // @ts-ignore
      symbol: { type: 'simple-marker', color: [0, 0, 255, 0.8], size: '8px' }
    });
  }

  private createMultipointGraphic(coordinates) {
    const multipoint = new Multipoint({
      points: coordinates,
      spatialReference: { wkid: 4326 }
    });
    return new Graphic({
      geometry: multipoint,
      // @ts-ignore
      symbol: { type: 'simple-marker', color: [0, 0, 255, 0.8], size: '8px' }
    });
  }

  private createPolylineGraphic(coordinates, isMultiLineString = false) {
    const paths = isMultiLineString ? coordinates : [coordinates];
  
    const polyline = new Polyline({
      paths: paths, // Pass the entire array of paths
      spatialReference: { wkid: 4326 }
    });
  
    return new Graphic({
      geometry: polyline,
      symbol: {
        // @ts-ignore
        type: 'simple-line',
        color: [0, 0, 255, 0.8], // Blue color
        width: 2
      }
    });
  }
  

}

import { Injectable } from '@angular/core';
import { AppHttpService } from './app-http.service';
import { map } from 'rxjs/operators';
import { Account } from 'src/app/shared/models';
import { Ability } from '@casl/ability';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';

@Injectable({ providedIn: 'root' })
export class AuthService {
  redirectUrl: string;
  account: Account;

  constructor(
    private httpService: AppHttpService,
    private ability: Ability,
  ) { }

  /**
   * Fetches current user-info from local storage
   */
  getCurrentUser(): Account {
    try {
      if (!this.account || !this.account.token) {
        this.account = new Account(JSON.parse(localStorage.getItem('currentUser')));
        this.updateAbility(this.account.abilities);
      }
      return this.account;
    } catch (e) {
      this.logout();
      return null;
    }
  }

  private generateRandomString(length: number): string {
    const array = new Uint8Array(length);
    crypto.getRandomValues(array);
    return Array.from(array, (byte) =>
      ('0' + (byte % 36).toString(36)).slice(-1)
    ).join('');
  }

  private generateCodeChallenge(codeVerifier: string): string {
    const hash = CryptoJS.SHA256(codeVerifier);
    return CryptoJS.enc.Base64.stringify(hash)
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+$/, '');
  }

  initializeLoginFlow(): string {
    const codeVerifier = this.generateRandomString(128); // Generate a secure random string
    const codeChallenge = this.generateCodeChallenge(codeVerifier);

    // Store code_verifier for later use
    sessionStorage.setItem('code_verifier', codeVerifier);

    // Generate the login URL
    const loginUrl =
      `${environment.AZURE_AUTH_URL}?client_id=${environment.AZURE_CLIENT_ID}` +
      `&scope=openid profile offline_access` +
      `&redirect_uri=${environment.AZURE_REDIRECT_URI}` +
      `&state=random_state` +
      `&code_challenge=${codeChallenge}` +
      `&code_challenge_method=S256` +
      `&response_mode=fragment` +
      `&response_type=code` +
      `&prompt=login`;

    return loginUrl;
  }

  loginWithAzureSSO(code: string) {
    const codeVerifier = sessionStorage.getItem('code_verifier');
    const payload = { code, redirectUri: environment.AZURE_REDIRECT_URI, codeVerifier };
    
    return this.httpService.postService('/v1/login/azure', payload).pipe(
      map(resp => {
        const user = resp.data;
        user.token = resp.token;
        user.abilities = resp.abilities;
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.updateAbility(resp.abilities);

        return { status: 'success', redirectUrl: this.redirectUrl || this.getDefaultUrl() };
      })
    );
  }

  updateAbility(rules = []): void {
    this.ability.update(rules);
  }

  /**
   * Clears current user from local storage
   */
  clearCurrentUser() {
    try {
      localStorage.removeItem('currentUser');
      this.account = null;
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * Returns true if user is logged in.
   * @returns boolean
   */
  isLoggedIn() {
    return 'token' in (this.getCurrentUser() || {});
  }

  /**
   * Makes login api call to the server
   * @param email login username
   * @param password login password
   */
  login(username: string, password: string) {
    const payload = { username, password };
    return this.httpService.postService('/v1/login', payload).pipe(
      map(resp => {
        const user = resp.data;
        user.token = resp.token;
        user.abilities = resp.abilities;
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.updateAbility(resp.abilities);

        return { status: 'success', redirectUrl: this.redirectUrl || this.getDefaultUrl() };
      })
    );
  }

  /**
   * Removes user info from local storage
   */
  logout() {
    return this.httpService.postService('/v1/logout', {}).pipe(
      map(resp => {
        this.clearCurrentUser();
        this.redirectUrl = null;
        this.updateAbility([]);
        return { status: 'success' };
      })
    );
  }

  getDefaultUrl() {
    let url = '/';
    const account = this.getCurrentUser();
    if (account.role === 'super-admin') {
      url = '/organization';
    }
    return url;
  }

  /**
   * Fetch acl rules from server and update
   */
  refreshAbilities(): void {
    this.httpService.getService('/v1/appdata').toPromise()
      .then((resp: any) => {
        if (resp?.data?.abilities) {
          const user = this.getCurrentUser();
          user.abilities = resp.data.abilities;
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.updateAbility(resp.data.abilities);
        } else {
          console.error('Failed to fetch acl abilities');
        }
      })
      .catch(e => {
        console.error('Failed to fetch acl abilities', e);
      });
  }

  /**
   * Store updated account details
   * @param account Account
   */
  updateAccountDetails(account: Account) {
    const user = Object.assign(this.getCurrentUser(), account);
    localStorage.setItem('currentUser', JSON.stringify(user));
  }

  /**
   * Check whether user exists
   * @param email login username
   * @param password login password
   */
  checkUser(email: string = null, phone: string = null) {
    const qp = { email, phone };
    return this.httpService.getService('/v1/login/check-user', qp).toPromise();
  }

  /**
   * Requests magic link to this email
   * @param email email
   * @returns {Promise<any>}
   */
  getMagicLink(email: string) {
    const payload = { email };
    return this.httpService.postService('/v1/login/magic', payload).toPromise();
  }

  /**
   * Login with magic link
   * @param email email
   * @returns {Promise<any>}
   */
  loginWithMagicCode(code: string) {
    const payload = { code };
    return this.httpService.postService('/v1/login/magic/verify', payload).toPromise()
      .then((resp: any) => {
        const user = resp.data;
        user.token = resp.token;
        user.abilities = resp.abilities;
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.updateAbility(resp.abilities);

        return { status: 'success', redirectUrl: this.redirectUrl || this.getDefaultUrl() };
      });
  }

  /**
   * Request OTP
   * @param phone phone
   * @param otp otp
   */
  requestOTP(phone: string) {
    const payload = { phone };
    return this.httpService.postService('/v1/login/phone', payload).toPromise();
  }

  /**
   * Makes login api with OTP
   * @param phone phone
   * @param otp otp
   */
  loginWithOTP(phone: string, otp: string) {
    const payload = { phone, otp };
    return this.httpService.postService('/v1/login/phone/verify', payload).toPromise()
      .then((resp: any) => {
        const user = resp.data;
        user.token = resp.token;
        user.abilities = resp.abilities;
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.updateAbility(resp.abilities);

        return { status: 'success', redirectUrl: this.redirectUrl || this.getDefaultUrl() };
      });
  }
}

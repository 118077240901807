<map-popup-wrapper
  style="position: relative"
  id="popup"
  *ngIf="showPopup"
  [popupContent]="popupContent"
  [screenPoint]="screenPoint"
  (showPopupChange)="showPopupChange($event)"
/>

<app-map-tooltip
  style="position: relative"
  id="tooltip"
  *ngIf="showTooltip"
  [screenPoint]="screenPoint"
  [tooltipContent]="tooltipContent"
/>

<div class="wrapper">
  <!--  GHOST LOCATION MARKER  -->
  <div
    *ngIf="ghostLocations?.length > 0"
    class="ghost-location-count"
    [class.clickable]="isFullscreen"
    (click)="isFullscreen && onGhostLocationClick()"
  >
    <mat-icon
      class="no-location-icon"
      svgIcon="no-location"
    ></mat-icon>
    <div>
      {{ ghostLocations?.length > 9 ? '9+' : ghostLocations?.length }}
    </div>
  </div>

  <div
  class="circle-container"
  *ngIf="noLocatioImageList[0]?.data?.level > 2 && imageLoaded"
  [class.clickable]="isFullscreen"
  (click)="isFullscreen && onNoLocationImageClick()"
>
  <div class="circular-image">
    <img [src]="noLocatioImageList[0]?.url"
     alt="Profile Image"
     (error)="imageLoaded = false"
    (load)="imageLoaded = true" />
  
  </div>
  <div
    *ngIf="noLocatioImageList[0]?.data?.picturesList?.length > 0"
    class="No-location-count"
    [class.clickable]="isFullscreen"
  >
    <mat-icon class="no-location-icon" svgIcon="no-location"></mat-icon>
    <div class="no-location-count-text">
      {{ noLocatioImageList[0]?.data?.picturesList?.length > 9 ? '9+' : noLocatioImageList[0]?.data?.picturesList?.length }}
    </div>
  </div>
</div>

  
<div
  *ngIf="dataLevels.length < 2"
  class="map-action filters-section"
  (click)="toggleFiltersSection()"
>
  <mat-icon
    class="filters-icon"
    svgIcon="filters"
  />
</div>

<app-date-picker-overlay
  class="date-picker-overlay"
  [initialDate]="selectedDate"
  (onDateSelection)="handleDateChange($event)"
></app-date-picker-overlay>

  <!--  Close BUTTON  -->
  <div
    class="map-action close-section"
    *ngIf="isFullscreen"
    (click)="closeMap()"
  >
    <mat-icon
      class="sidenav-icon"
    >close
    </mat-icon>
  </div>

  <!--  ZOOM IN BUTTON  -->
  <div
    class="map-action zoom-in-section"
    (click)="zoomIn()"
  >
    <mat-icon
      class="zoom-in-icon"
      svgIcon="plus"
    />
  </div>

  <!--  ZOOM OUT BUTTON  -->
  <div
    class="map-action zoom-out-section"
    (click)="zoomOut()"
  >
    <mat-icon
      class="zoom-out-icon"
      svgIcon="minus"
    />
  </div>

  <!--  FULL SCREEN BUTTON  -->
  <div
    *ngIf="!isFullscreen"
    class="map-action full-screen-section"
    (click)="toggleFullScreen()"
  >
    <mat-icon
      class="full-screen-icon"
      svgIcon="full-screen"
    />
  </div>

  <!--  MAP FILTER / LEGEND  -->
  <app-project-map-legend
    [@legendAnimation]="showFilters ? 'visible': 'hidden'"
    [mapComponentInstance]="mapComponentInstance"
    [data]="legendData"
    (handleSelectionToggle)="updateLegendDataSelection($event)"
    class="map-legend"
    *ngIf="showFilters"
  />

  <!--  MAP  -->
  <app-esri-map
    #projectMap
    id="projectMap"
    class="project-map"
    [allowMarkerClick]="isFullscreen"
    (markerClick)="isFullscreen && onMarkerClick($event)"
    (markerHover)="onShowTooltip($event)"
    (mapLoaded)="onMapLoaded($event)"
    (mapComponentLoaded)="onMapComponentLoaded($event)"
    (viewLoaded)="onViewLoaded($event)"
  />
</div>

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-location-section',
  templateUrl: './location-section.component.html',
  styleUrls: ['./location-section.component.scss'],
})
export class LocationSectionComponent {
  @Input() latitude: any;
  @Input() longitude: any;
  @Input() isEditable:boolean = true;
  @Output() onDelete: EventEmitter<any> = new EventEmitter();

  deleteLocation() {
    this.onDelete.emit();
  }
}

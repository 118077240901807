<div class="date-picker-wrapper">
    <!-- Collapsed State -->
    <div class="collapsed-picker" (click)="toggleDatePicker()">
      <span class="selected-date">{{ getDisplayedDate() }}</span>
      <mat-icon class="expand-icon" svgIcon="calendar" *ngIf="!isExpanded"></mat-icon>
    <!-- Expanded State -->
    <div *ngIf="isExpanded" class="expanded-picker">
      <!-- Horizontal list of previous 10 dates -->
      <div class="dates-row"  *ngFor="let date of datesList;let i = index">
          <ng-container *ngIf="i > 0">
          <div
          class="date-option"
         
          [class.selected]="selectedDate.isSame(date, 'day')"
          (click)="selectDate(date)"
        >
       
        {{
          formatDate(date, 'DD')
        }}
   
        </div>
      </ng-container>

      </div>
<!-- Invisible Input (dummy input for datepicker) -->
 <div>

<!-- Calendar Icon to trigger Datepicker -->
<mat-icon
  class="expand-icon"
  (click)="toggleCalendar($event)"
  svgIcon="calendar">
  <input matInput [matDatepicker]="picker" [(ngModel)]="selectedDate" (dateChange)="onCalendarDateChange($event)" class="hidden-datepicker-input">

</mat-icon>

<!-- MatDatepicker Component -->
<mat-datepicker #picker   class="expand-icon"
panelClass="custom-datepicker-panel"  (dateChange)="onCalendarDateChange($event)"></mat-datepicker>
      <!-- Date Picker for manual selection -->
    </div>
    </div>
    </div>

  </div>
  
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input, OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ProjectMapService } from '../../project-map.service';

@Component({
  selector: 'app-project-map-legend',
  templateUrl: './project-map-legend.component.html',
  styleUrls: ['./project-map-legend.component.scss'],
})

export class ProjectMapLegendComponent implements OnInit {
  @ViewChild('filterComponent') filterComponent: ElementRef;
  @Input() mapComponentInstance: any;
  @Input() data: any[];
  @Output() handleSelectionToggle: EventEmitter<any> = new EventEmitter<any>();

  selectedTabIndex = 0;

  constructor(
    private projectMapService: ProjectMapService,
  ) {
  }

  ngOnInit() {
    this.selectedTabIndex = this.data.findIndex(o => o.isSelected);
    this.updateDataForTypesOnly();
  }
  
  public updateDataForTypesOnly() {
    const availableCategories = this.mapComponentInstance
      .getProjectLayerCategories()
      .map(category => category.toLowerCase()); // Convert categories to lowercase
  
    // Update only for "types" (index 1)
    if (this.data[1]?.category === "types") {
      this.data[1].children.forEach(child => {
        child.disabled = !availableCategories.includes(child.key.toLowerCase());
        child.isSelected = availableCategories.includes(child.key.toLowerCase());
      });
    }
  }
  
  
  handleClick(type, checked) {
    switch (type) {
      case 'bid_items':
      case 'deliveries':
      case 'quality_test':
      case 'issues':
      case 'labor_equipment':
      case 'activities':
      case 'alignment':
        this.mapComponentInstance?.toggleSiteLayers(type, checked);
        break;
      case 'boundary':
        this.mapComponentInstance?.toggleGraphicsLayerVisibility(checked);
      break;       
      default:
        this.mapComponentInstance?.toggleProjectLayers(type, checked);
        break;
    }

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.data.length; i++) {
      const tab = this.data[i];
      tab.isSelected = false;

      let found = false;
      // tslint:disable-next-line:prefer-for-of
      for (let j = 0; j < tab.children.length; j++) {
        if (tab.children[j].key === type) {
          tab.children[j].isSelected = checked;
          found = true;
          break;
        }
      }

      if (found) {
        tab.isSelected = true;
        this.handleSelectionToggle.emit(this.data);
        break;
      }
    }
  }

  hide() {
    this.projectMapService.closeFilters.next(null);
  }

  @HostListener('document:mousedown', ['$event'])
  handleOutsideClick(event) {
    const popupRect = this.filterComponent.nativeElement.getBoundingClientRect();
    const isInsidePopup =
      event.button === 0 &&
      event.clientX >= popupRect.left &&
      event.clientX <= popupRect.right &&
      event.clientY >= popupRect.top &&
      event.clientY <= popupRect.bottom;

    if (!isInsidePopup) {
      this.hide();
    }
  }
}

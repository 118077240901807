import { Component, ComponentFactory, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CreateDailyReportComponent } from '../components/create-daily-report/create-daily-report.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectsService } from '../core/projects.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommonService } from '../../../shared/services/common.service';
import {
  BidItemDetailsAddBidItemComponent,
} from '../pages/site-details-page/tab-screens/daily-report/daily-report-components/detail-components/bid-item-details/bid-item-details-add-bid-item/bid-item-details-add-bid-item.component';
import {
  ActivityItemDetailsComponent,
} from '../pages/site-details-page/tab-screens/daily-report/daily-report-components/detail-components/activitiy-details/activity-item-details/activity-item-details.component';
import {
  AddDeliveryDetailsComponent,
} from '../pages/site-details-page/tab-screens/daily-report/daily-report-components/detail-components/delivery-details/add-delivery-details/add-delivery-details.component';
import {
  AddUsageDetailsComponent,
} from '../pages/site-details-page/tab-screens/daily-report/daily-report-components/detail-components/usage-details/add-usage-details/add-usage-details.component';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../state/app.state';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../../../shared/components';
import { SetCurrentLocation } from '../core/projects.actions';
import * as $ from 'jquery';
import {
  LaborDetailsWrapperComponent,
} from '../pages/site-details-page/tab-screens/daily-report/daily-report-components/detail-components/labor-details/labor-details-wrapper/labor-details-wrapper.component';
import { dailyReport, projectDetails } from '../core/projects.selectors';
import {
  EquipmentDetailsWrapperComponent,
} from '../pages/site-details-page/tab-screens/daily-report/daily-report-components/detail-components/equipment-details/equipment-details-wrapper/equipment-details-wrapper.component';
import {
  IssueDetailsWrapperComponent,
} from '../pages/site-details-page/tab-screens/daily-report/daily-report-components/detail-components/issue-details/issue-details-wrapper/issue-details-wrapper.component';
import {
  WeatherDetailsWrapperComponent,
} from '../pages/site-details-page/tab-screens/daily-report/daily-report-components/detail-components/weather-details/weather-details-wrapper/weather-details-wrapper.component';
import { MapService } from 'src/app/shared/services/map.service';

@Component({
  selector: 'app-projects-wrapper',
  templateUrl: './projects-wrapper.component.html',
  styleUrls: ['./projects-wrapper.component.scss'],
  animations: [
    trigger('rightAnimation', [
      state(
        '*',
        style({
          width: '0px',
        }),
      ),
      state(
        'sidenavOpen',
        style({
          width: '535px',
        }),
      ),
      state(
        'sidenavClosed',
        style({
          width: '0px',
        }),
      ),
      transition('*=>sidenavOpen', animate('500ms')),
      transition('* => void, * => sidenavClosed', animate('500ms')),
    ]),
    trigger('legendAnimation', [
      state(
        'visible',
        style({
          transform: 'translate(0%, 0%) scale(1)',
          opacity: 1,
        }),
      ),
      state(
        'void, hidden',
        style({
          transform: 'translate(-30%, 25%) scale(0.4)',
          opacity: 0,
        }),
      ),
      transition('* => visible', animate('250ms')),
      transition('* => void, * => hidden', animate('250ms')),
    ]),
  ],
})
export class ProjectsWrapperComponent implements OnInit, OnDestroy {
  @ViewChild('container', { read: ViewContainerRef }) container;
  isFullScreen = false;
  isLevelThreeMarkerClicked = false;
  showSidenav = false;
  pageSlug: string;
  isDailyReportMenuOpen = false;
  dailyReportMenuConfig = [
    {
      icon: 'daily-report-page-menu-bid-item',
      label: 'Bid item',
      handler: () => this.triggerAddElementToDailyReport('bidItem'),
    },
    {
      icon: 'daily-report-page-menu-delivery',
      label: 'Delivery',
      handler: () => this.triggerAddElementToDailyReport('delivery'),
    },
    {
      icon: 'daily-report-page-menu-usage',
      label: 'Usage',
      handler: () => this.triggerAddElementToDailyReport('usage'),
    },
    {
      icon: 'daily-report-page-menu-external-test',
      label: 'External test',
      handler: () => {
        console.log('Add');
      },
    },
    {
      icon: 'daily-report-page-menu-internal-test',
      label: 'Internal test',
      handler: () => {
        console.log('Add');
      },
    },
    {
      icon: 'daily-report-page-menu-issues',
      label: 'Issues',
      handler: () => {
        this.triggerAddElementToDailyReport('issues');
      },
    },
    {
      icon: 'daily-report-page-menu-labor',
      label: 'Labor',
      handler: () => {
        this.triggerAddElementToDailyReport('labor');
      },
    },
    {
      icon: 'daily-report-page-menu-equipment',
      label: 'Equipment',
      handler: () => {
        this.triggerAddElementToDailyReport('equipment');
      },
    },
    {
      icon: 'daily-report-page-menu-activities',
      label: 'Activities',
      handler: () => {
        this.triggerAddElementToDailyReport('activity');
      },
    },
  ];
  dailyReport: any;
  data:any;
  issues: any;
  report: any = {};
  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private projectsService: ProjectsService,
    private mapService:MapService,
    private resolver: ComponentFactoryResolver,
    private commonService: CommonService,
    private store: Store<fromRoot.State>,
  ) {
    this.projectsService.closeCreateDailyReportDialog
      .pipe()
      .subscribe(() => {
        this.dialog.closeAll();
      });

    this.route.queryParams
      .pipe(takeUntil(this.onDestroy))
      .subscribe(qp => {
        this.isFullScreen = 'fs' in qp;
      });

    this.projectsService.openRightPanel
      .pipe(takeUntil(this.onDestroy))
      .subscribe(params => {
        this.showSidenav = true;
        setTimeout(() => {
          this.createComponent(params?.component, params?.data, params?.options);
        }, 300);
      });

    this.projectsService.closeRightPanel
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.router.navigate([], {
          queryParams: {
            selectedId: null,
          },
          queryParamsHandling: 'merge',
        }).then(() => {
          this.showSidenav = false;
          this.container?.clear();
        });
      });

    this.store.select(dailyReport)
      .pipe(takeUntil(this.onDestroy))
      .subscribe((report: any) => {
        this.report = report;

        if (this.report?.weather === null || this.report?.weather === undefined) {
          // Check if 'Weather' is already in the menu config before adding
          const exists = this.dailyReportMenuConfig.some(item => item.label === 'Weather');
          if (!exists) {
            this.dailyReportMenuConfig.unshift(
              {
                icon: 'daily-report-page-weather',
                label: 'Weather',
                handler: () => this.triggerAddElementToDailyReport('weather'),
              },
            );
          }
        } else {
          // Remove 'Weather' from the menu config if it exists
          const index = this.dailyReportMenuConfig.findIndex(item => item.label === 'Weather');
          if (index !== -1) {
            this.dailyReportMenuConfig.splice(index, 1);
          }
        }
      });
  }

  ngOnInit() {
    this.isLevelThreeMarkerClicked = this.commonService.getCurrentlevelThreeClick();
    this.commonService.levelThreeMarkerData
    .pipe(takeUntil(this.onDestroy))
      .subscribe((data) => {
        this.data=data;
    }); 

    this.commonService.levelThreeClick$
    .pipe(takeUntil(this.onDestroy))
    .subscribe(() => {
      this.isLevelThreeMarkerClicked = this.commonService.getCurrentlevelThreeClick();      
    }); 

    navigator.geolocation.getCurrentPosition((position) => {
      const location = {
        longitude: position?.coords?.longitude || null,
        latitude: position?.coords?.latitude || null,
      };

      this.store.dispatch(SetCurrentLocation({ location }));
    });
  }

  close() {
    const isFormDirty = this.projectsService.isAnyFormDirty.getValue();

    if (isFormDirty) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        disableClose: true,
        data: new ConfirmDialogModel(
          'Unsaved changes',
          `There are unsaved changes.<br/>Are you sure you want to discard?`,
        ),
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.projectsService.closeRightPanel.next(null);
          this.projectsService.isAnyFormDirty.next(false);
        }
      });
    } else {
      this.projectsService.closeRightPanel.next(null);
      this.projectsService.isAnyFormDirty.next(false);
    }
  }

goBack() {
    if(this.data){
      this.mapService.openSidenavOnMarkerClick(this.data);
    }
    this.isLevelThreeMarkerClicked=false;
}


  createComponent(component, data, options = {}) {
    this.container?.clear();

    const factory: ComponentFactory<any> = this.resolver.resolveComponentFactory(component);
    const componentRef = this.container?.createComponent(factory);
    componentRef.instance.data = data;
    componentRef.instance.options = options;
  }

  handleAdd() {
    const currentRoute = this.router.url.split('?')[0];

    if (currentRoute === '/projects') {
      this.dialog.open(CreateDailyReportComponent, {
        disableClose: false,
        data: { x: 3 },
        panelClass: 'floating-dialog-box',
      });
    }

    const projectDetailsPageUrlRegex = /^\/projects\/[a-zA-Z0-9]{20,25}$/;
    if (projectDetailsPageUrlRegex.test(currentRoute)) {
      this.dialog.open(CreateDailyReportComponent, {
        disableClose: false,
        panelClass: 'floating-dialog-box',
        data: {
          projectId: currentRoute.split('/').pop(),
        },
      });
    }

    const siteDetailsUrlRegex = /^\/projects\/[a-zA-Z0-9]{20,25}\/sites\/[a-zA-Z0-9]{20,25}$/;
    if (
      siteDetailsUrlRegex.test(currentRoute) &&
      this.route.snapshot.queryParams['tab'] &&
      this.route.snapshot.queryParams['tab'] === 'daily-report'
      && Object.keys(this.report).length > 0
    ) {
      this.pageSlug = 'daily-report';
      this.isDailyReportMenuOpen = true;
    } else {
      const date = this.route.snapshot.queryParams['date'];

      this.dialog.open(CreateDailyReportComponent, {
        disableClose: false,
        panelClass: 'floating-dialog-box',
        data: {
          siteId: currentRoute.split('/').pop(),
          projectId: currentRoute.split('/')[2],
          date,
        },
      });
    }
  }

  triggerAddElementToDailyReport(type) {
    const isFormDirty = this.projectsService.isAnyFormDirty.getValue();

    if (isFormDirty) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        disableClose: true,
        data: new ConfirmDialogModel(
          'Unsaved changes',
          `There are unsaved changes.<br/>Are you sure you want to discard?`,
        ),
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.addElementToDailyReport(type);
          this.projectsService.isAnyFormDirty.next(false);
        }
      });
    } else {
      this.addElementToDailyReport(type);
    }

    this.isDailyReportMenuOpen = false;
  }

  addElementToDailyReport(type) {
    switch (type) {
      case 'weather':
        this.router.navigate([], {
          queryParams: {
            expandedSection: 'weather',
          },
          queryParamsHandling: 'merge',
        }).then(() => {
          setTimeout(() => {
            this.projectsService.openRightPanel.next({
              component: WeatherDetailsWrapperComponent,
            });
            $(`#weather`)[0]?.scrollIntoView({
              behavior: 'smooth', // or "auto" or "instant"
              block: 'start', // or "end"
            });
          });
        });
        break;

      case 'bidItem':
        this.router.navigate([], {
          queryParams: {
            expandedSection: 'bid-item',
          },
          queryParamsHandling: 'merge',
        }).then(() => {
          setTimeout(() => {
            this.projectsService.openRightPanel.next({
              component: BidItemDetailsAddBidItemComponent,
            });
            $(`#bid-item`)[0]?.scrollIntoView({
              behavior: 'smooth', // or "auto" or "instant"
              block: 'start', // or "end"
            });
          });
        });
        break;

      case 'delivery':
        this.router.navigate([], {
          queryParams: {
            expandedSection: 'delivery-usage',
            idx: '0',
          },
          queryParamsHandling: 'merge',
        }).then(() => {
          setTimeout(() => {
            this.projectsService.openRightPanel.next({
              component: AddDeliveryDetailsComponent,
            });
            $(`#delivery-usage`)[0]?.scrollIntoView({
              behavior: 'smooth', // or "auto" or "instant"
              block: 'start', // or "end"
            });
          });
        });
        break;

      case 'usage':
        this.router.navigate([], {
          queryParams: {
            expandedSection: 'delivery-usage',
            idx: 1,
          },
          queryParamsHandling: 'merge',
        }).then(() => {
          setTimeout(() => {
            this.projectsService.openRightPanel.next({
              component: AddUsageDetailsComponent,
            });
            $(`#delivery-usage`)[0]?.scrollIntoView({
              behavior: 'smooth', // or "auto" or "instant"
              block: 'start', // or "end"
            });
          });
        });
        break;

      case 'activity':
        this.router.navigate([], {
          queryParams: {
            expandedSection: 'activities',
          },
          queryParamsHandling: 'merge',
        }).then(() => {
          setTimeout(() => {
            this.projectsService.openRightPanel.next({
              component: ActivityItemDetailsComponent,
            });
            $(`#activities`)[0]?.scrollIntoView({
              behavior: 'smooth', // or "auto" or "instant"
              block: 'start', // or "end"
            });
          });
        });
        break;

      case 'labor':
        this.router.navigate([], {
          queryParams: {
            expandedSection: 'labor-equipment',
            idx: -1,
          },
          queryParamsHandling: 'merge',
        }).then(() => {
          setTimeout(() => {
            this.store.select(projectDetails).subscribe(data => {
              this.projectsService.openRightPanel.next({
                component: LaborDetailsWrapperComponent,
                data: {
                  newDataFormat: data.new_labor_format,
                },
              });
              $(`#labor-equipment`)[0]?.scrollIntoView({
                behavior: 'smooth', // or "auto" or "instant"
                block: 'start', // or "end"
              });
            }).unsubscribe();
          });
        });
        break;

      case 'equipment':
        this.router.navigate([], {
          queryParams: {
            expandedSection: 'labor-equipment',
            idx: 1,
          },
          queryParamsHandling: 'merge',
        }).then(() => {
          setTimeout(() => {
            this.store.select(projectDetails).subscribe(data => {
              this.projectsService.openRightPanel.next({
                component: EquipmentDetailsWrapperComponent,
                data: {
                  newDataFormat: data.new_labor_format,
                },
              });
              $(`#labor-equipment`)[0]?.scrollIntoView({
                behavior: 'smooth', // or "auto" or "instant"
                block: 'start', // or "end"
              });
            }).unsubscribe();
          });
        });
        break;

      case 'issues':
        this.router.navigate([], {
          queryParams: {
            expandedSection: 'issues',
          },
          queryParamsHandling: 'merge',
        }).then(() => {
          setTimeout(() => {

            this.projectsService.openRightPanel.next({
              component: IssueDetailsWrapperComponent,
            });
            $(`#issues`)[0]?.scrollIntoView({
              behavior: 'smooth', // or "auto" or "instant"
              block: 'start', // or "end"
            });
          });
        });
        break;

      default:
        break;
    }
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}

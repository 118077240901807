import { Component, EventEmitter, Input, Output, OnInit, OnChanges, SimpleChanges, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-date-picker-overlay',
  templateUrl: './date-picker-overlay.component.html',
  styleUrls: ['./date-picker-overlay.component.scss'],
})
export class DatePickerOverlayComponent implements OnInit, OnChanges {
  @Input() initialDate: any = null; // Date from horizontal date picker
  @Output() onDateSelection = new EventEmitter<string>();
  private onDestroy$ = new Subject<void>(); // Cleanup signal
  @ViewChild('picker', { static: false }) picker: MatDatepicker<any>;
  
  isExpanded = false; // Controls the collapse/expand state
  datesList = []; // Holds the previous 10 dates
  selectedDate: moment.Moment;

  constructor(  private cdRef: ChangeDetectorRef, private commonService:CommonService) {

  }


 ngOnChanges(changes: SimpleChanges): void {
    this.selectedDate = this.initialDate ? moment(this.initialDate) : moment();
    this.updateDateList(); // Generate the previous 10 dates
    
  }

  ngOnInit(): void {
    // Initialize the selected date with the input date or current date
    this.selectedDate = this.initialDate ? moment(this.initialDate) : moment();
    this.updateDateList(); // Generate the previous 10 dates
    // Subscribe to the service state
    this.commonService.isExpanded$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(value => {
        console.log('Child received expanded state:', value);
        this.isExpanded = value;
        this.cdRef.detectChanges();
      });
    
  }

  // Generates the previous 10 dates including the selected date
  updateDateList(): void {
    this.datesList = this.generatePrevDates(this.selectedDate, 10);
  }

  // Generates `count` previous dates from the given date
  generatePrevDates(startDate: moment.Moment, count: number): moment.Moment[] {
    const dates = [];
    for (let i = 0; i < count; i++) {
      dates.push(moment(startDate).subtract(i, 'days'));
    }
    return dates;
  }

  // Toggles the collapse/expand state
  toggleDatePicker(): void {
    this.isExpanded = !this.isExpanded;
  }

  // Handles manual date selection and updates the view
  selectDate(date: moment.Moment): void {
    this.selectedDate = date; // Update the selected date
    this.updateDateList(); // Update the previous 10 dates
    this.onDateSelection.emit(date.format('YYYY-MM-DD')); // Emit the selected date
  }

  // Get the display text for the selected date
  getDisplayedDate(): string {
    if (this.selectedDate.isSame(moment(), 'day')) {
      return `Today, ${this.selectedDate.format('MMM DD')}`;
    }
    return `${this.selectedDate.format('MMM DD')}`;
  }

  // Handles date selection from the calendar picker
  onCalendarDateChange(event: any): void {
    const newDate = moment(event.value); // Get the selected date
    this.selectDate(newDate); // Update the date and the list
  this.isExpanded = false; // Controls the collapse/expand state
  this.cdRef.detectChanges();


  }

  isToday(date: moment.Moment): boolean {
    return date.isSame(moment(), 'day');
  }
  
  formatDate(date: moment.Moment, format: string): string {
    return date.format(format);
  }

  // Toggle the calendar view
  toggleCalendar(event: MouseEvent): void {
    event.stopImmediatePropagation();  // Prevent event propagation

    if (this.picker) {
      this.picker.open();  // Open the date picker
    }
  }

  ngOnDestroy() {
    // Cleanup to avoid memory leaks
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
  
}

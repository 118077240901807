<div class="sidebar">
  <button [mat-dialog-close]="componentResult" class="dialog-close" mat-mini-fab aria-label="Close Sidebar"
    *ngIf="dialogOptions.closable">
    <mat-icon title="Close Sidebar">close</mat-icon>
  </button>

  <ng-container *ngIf="initialized">
    <div class="content">
      <form [formGroup]="inputForm">
        <div class="container">

          <!-- Invite Scope -->
          <section>
            <div class="mat-subheading-1 row" *ngIf="!editing">Invite to:</div>
            <div class="mat-body capitalize" *ngIf="!editing">
              <ng-container *ngIf="invitation.site?.id">Site {{invitation.site?.name}}</ng-container>
              <ng-container *ngIf="!invitation.site?.id && invitation.project?.id">
                Project {{invitation.project?.name}}
              </ng-container>

            </div>
            <mat-form-field *ngIf="editing">
              <mat-label>Invite to</mat-label>
              <mat-select formControlName="scope_id" [errorStateMatcher]="matcher"
                (selectionChange)="onScopeChange($event.value)">
                <mat-option *ngFor="let scope of scopes" [value]="scope.id">
                  <div [class.pad-l-20]="scope.child">{{scope.name}}</div>
                </mat-option>
              </mat-select>
              <mat-error *ngIf="inputForm.controls.scope_id.hasError('required')">
                This field is <strong>required</strong>
              </mat-error>
              <mat-error *ngIf="inputForm.controls.scope_id.hasError('error')">
                {{inputForm.controls.scope_id.errors.error}}
              </mat-error>
            </mat-form-field>
          </section>
          <!-- /Invite Scope -->

          <!-- Role -->
          <section>
            <div class="mat-subheading-1 row" *ngIf="!editing">Role:</div>
            <div class="mat-body capitalize" *ngIf="!editing">
              <ng-container *ngIf="invitation.role?.id">{{invitation.role?.name}}</ng-container>
              <ng-container *ngIf="!invitation.role?.id">{{invitation.role_code}}</ng-container>
            </div>
            <mat-form-field *ngIf="editing">
              <mat-label>Role</mat-label>
              <mat-select formControlName="role_code" [errorStateMatcher]="matcher">
                <mat-option *ngFor="let role of roles" [value]="role.code" [disabled]="role.scope !== _scopeType">
                  {{role.label}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="inputForm.controls.role_code.hasError('required')">
                This field is <strong>required</strong>
              </mat-error>
              <mat-error *ngIf="inputForm.controls.role_code.hasError('error')">
                {{inputForm.controls.role_code.errors.error}}
              </mat-error>
            </mat-form-field>
          </section>
          <!-- /Role -->

          <!-- SSO Option -->
           <section>
            <mat-checkbox [formControl]="inputForm.get('sso_flag')" [ngClass]="{ 'disabled-checkbox': !editing }">Uses sso</mat-checkbox>
          </section>
           <!-- /SSO Option -->

          <!-- First Name -->
          <section>
            <div class="mat-subheading-1 row">First name:</div>
            <div class="mat-body capitalize" *ngIf="!editing">{{invitation.first_name}}</div>
            <mat-form-field *ngIf="editing">
              <mat-label>First name</mat-label>
              <input matInput type="text" autocomplete="off" formControlName="first_name"
                [errorStateMatcher]="matcher" />
              <mat-error *ngIf="inputForm.controls.first_name.hasError('required')">
                This field is <strong>required</strong>
              </mat-error>
              <mat-error *ngIf="inputForm.controls.first_name.hasError('error')">
                {{inputForm.controls.first_name.errors.error}}
              </mat-error>
            </mat-form-field>
          </section>
          <!-- /First Name -->

          <!-- Last Name -->
          <section>
            <div class="mat-subheading-1 row">Last name:</div>
            <div class="mat-body capitalize" *ngIf="!editing">{{invitation.last_name}}</div>
            <mat-form-field *ngIf="editing">
              <mat-label>Last name</mat-label>
              <input matInput type="text" autocomplete="off" formControlName="last_name"
                [errorStateMatcher]="matcher" />
              <mat-error *ngIf="inputForm.controls.last_name.hasError('required')">
                This field is <strong>required</strong>
              </mat-error>
              <mat-error *ngIf="inputForm.controls.last_name.hasError('error')">
                {{inputForm.controls.last_name.errors.error}}
              </mat-error>
            </mat-form-field>
          </section>
          <!-- /Last Name -->

          <!-- Email -->
          <section>
            <div class="mat-subheading-1 row">Email:</div>
            <div class="mat-body capitalize" *ngIf="!editing">{{invitation.email}}</div>
            <mat-form-field *ngIf="editing">
              <mat-label>Email</mat-label>
              <input matInput type="email" autocomplete="off" formControlName="email" [errorStateMatcher]="matcher" />
              <mat-error *ngIf="inputForm.controls.email.hasError('required')">
                This field is <strong>required</strong>
              </mat-error>
              <mat-error *ngIf="inputForm.controls.email.hasError('email')">
                Please enter a valid email
              </mat-error>
              <mat-error *ngIf="inputForm.controls.email.hasError('error')">
                {{inputForm.controls.email.errors.error}}
              </mat-error>
            </mat-form-field>
          </section>
          <!-- /Email -->

          <!-- Phone -->
          <section>
            <div class="mat-subheading-1 row">Phone:</div>
            <div class="mat-body" *ngIf="!editing">{{invitation.phone}}</div>
            <mat-form-field *ngIf="editing">
              <mat-label>Phone</mat-label>
              <input matInput type="email" autocomplete="off" formControlName="phone" [errorStateMatcher]="matcher" />
              <mat-error *ngIf="inputForm.controls.phone.hasError('required')">
                This field is <strong>required</strong>
              </mat-error>
              <mat-error *ngIf="inputForm.controls.phone.hasError('error')">
                {{inputForm.controls.last_name.errors.error}}
              </mat-error>
            </mat-form-field>
          </section>
          <!-- /Phone -->

          <section *ngIf="!editing && invitation.inviter?.id">
            <div class="mat-subheading-1 row">Invited by:</div>
            <div class="mat-body">{{invitation.inviter.name}} on {{invitation.created_at | dateFormat: 'long'}}</div>
          </section>

          <!-- Form Actions -->
          <section>
            <div class="row" *ngIf="editing">
              <button mat-stroked-button color="primary" (click)="onSubmit()">
                <ng-container>Invite</ng-container>
              </button>
              <button mat-stroked-button (click)="onCancel()">Cancel</button>
            </div>
          </section>
          <!-- Form Actions -->

        </div>
      </form>
    </div>
  </ng-container>
</div>

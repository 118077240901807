import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Project, Site } from 'src/app/shared/models';
import { AppErrorStateMatcher, SiteService } from 'src/app/shared/services';
import { Invitation } from '../../invitation';
import { InvitationService } from '../../invitation.service';

@Component({
  selector: 'app-project-invite-create',
  templateUrl: './project-invite-create.component.html',
  styleUrls: ['./project-invite-create.component.scss']
})
export class ProjectInviteCreateComponent implements OnInit {

  public roles: any[] = [
    { code: 'project-manager', label: 'Project Manager', scope: 'project' },
    { code: 'contractor', label: 'Counterparty', scope: 'project' },
    { code: 'contractor-pm', label: 'Contractor PM', scope: 'project' },  
    { code: 'lead-inspector', label: 'Lead Superintendent', scope: 'site' },
    { code: 'inspector', label: 'Superintendent', scope: 'site' },
    { code: 'contractor-inspector', label: 'Contractor Inspector', scope: 'site' },
  ];

  @Input() public data: any;
  @Input() public project: Project;
  @Input() public editable: boolean = false;

  public dialogOptions: any = {};
  public componentResult: any = null;

  public loading: number = 0;
  public initialized: boolean = false;
  public editing = false;

  public matcher = new AppErrorStateMatcher();
  public inputForm: UntypedFormGroup = this.fb.group({
    first_name: ['', [Validators.required]],
    last_name: [''],
    sso_flag: [false],
    email: ['', [Validators.required, Validators.email]],
    phone: ['', [Validators.required]],
    scope_id: ['', [Validators.required]],
    role_code: ['', [Validators.required]],
  });
  public scopes: any[] = [];
  public _scopeType: string = 'project';

  public invitation: Invitation;

  constructor(
    private siteSrv: SiteService,
    private invSrv: InvitationService,
    private snackBar: MatSnackBar,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ProjectInviteCreateComponent>,
    @Inject(MAT_DIALOG_DATA) public inputData: any,
    public dialog: MatDialog
  ) { }


  ngOnInit(): void {
    const { data, project, editable, options } = this.inputData;

    if (data) {
      this.dialogOptions = Object.assign(this.dialogOptions, options);
      this.data = Object.assign(data, { project });
      this.project = project;
      this.editable = editable;
    }

    this.invitation = new Invitation(JSON.parse(JSON.stringify(this.data)));
    this.toReactiveForm(this.invitation, this.inputForm);

    if (this.editable && !this.invitation.id) {
      this.editing = true;
    }

    this.fetchProjectSites(this.project)
      .then(() => this.initialized = true);

  }

  fetchProjectSites(project: Project) {
    return this.siteSrv.getProjectSites(project)
      .then((sites: Site[]) => {
        this.project.sites = sites;

        this.scopes = [
          { id: `project/${this.project?.id}`, name: `Project ${this.project?.name}` },
        ];
        if (this.project.sites.length) {
          this.project.sites.map(site => {
            this.scopes.push({ id: `site/${site?.id}`, name: `Site ${site?.name}`, child: true });
          });
        }

        return true;
      })
      .catch(e => {
        console.error(e);
      })
  }

  /**
   * Assign base model to FormGroup
   * @param model BaseModel
   * @param form FormGroup
   */
  toReactiveForm(model: Invitation, form: UntypedFormGroup) {
    form.controls.first_name.setValue(model.first_name);
    form.controls.last_name.setValue(model.last_name);
    form.controls.sso_flag.setValue(model.sso_flag);
    form.controls.email.setValue(model.email);
    form.controls.phone.setValue(model.phone);
  }

  /**
   * Cancel update, and ignore form changes
   */
  onCancel() {
    this.editing = false;
    this.invitation = new Invitation(JSON.parse(JSON.stringify(this.data)));
    if (!this.invitation?.id) {
      this.dialogRef.close();
    }
  }

  /**
   * Update ReportBidItem
   */
  onSubmit() {

    if (!this.inputForm.valid) {
      return;
    }

    const [ entity_type, entity_id ] = this.inputForm.value.scope_id.split('/');
    this.invitation = Object.assign(this.invitation, this.inputForm.value);
    const role = this.inputForm.value.role_code;
    const payload = this.invitation.toPayload();

    const qp = { include: [ 'role', 'invitee', 'inviter', 'site', 'project', 'organization' ] };
    const req = entity_type === 'site'
      ? this.invSrv.newInviteToSite(this.project.sites.find(o => o.id === entity_id), role, payload, qp)
      : this.invSrv.newInviteToProject(this.project, role, payload, qp);

    this.loading++;
    req.then((invite: Invitation) => {
      this.invitation = invite;
      this.toReactiveForm(this.invitation, this.inputForm);
      const message = invite.sso_flag ? 'SSO user added' : 'Sent invite';
      this.snackBar.open(message, '', { duration: 5000 });
      this.componentResult = invite;
      this.editing = false;
    })
    .catch((resp: HttpErrorResponse) => {
      if (resp.status === 422) {
        this.matcher.setServerErrors(this.inputForm, resp);
        return;
      }
      this.snackBar.open(resp.error?.error || 'Oops! something went wrong.', '', { duration: 5000 });
    })
    .finally(() => {
      this.loading--;
    });
  }

  onScopeChange(scope: string) {
    const [ entity_type, entity_id ] = this.inputForm.value.scope_id.split('/');
    this._scopeType = entity_type;
  }
}

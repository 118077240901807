<div class="wrapper flex flex-col">
  <div class="heading text-type-2">Issues</div>
  <app-issue-details-form
    [data]="data"
    [isMarkerClick]="isMarkerClick"
    class="issue-form"
  />
  <app-actions-section
    [disableOptionMenu]="disableOptionMenu"
    [hidden] = "isMarkerClick"
    [hideDeleteOption]="hideDeleteOption"
    class="actions-section w-[455px]"
  />
</div>

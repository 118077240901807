import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-button-select',
  templateUrl: './button-select.component.html',
  styleUrls: ['./button-select.component.scss'],
})
export class ButtonSelectComponent implements OnChanges, OnDestroy {
  @Input() label = '';
  @Input() options = [];
  @Input() parentFormGroup: FormGroup = new FormGroup<any>({});
  @Input() controlName = '';
  @Input() error = '';
  @Input() isEditable :boolean = true;
  @Output() checkForErrors = new EventEmitter();
  fieldSubscription: Subscription | undefined;
  isRequired = false;
  isDisabled = false;
  selectedOption = '';

  constructor(
    private cdRef: ChangeDetectorRef,
  ) {
  }

  select(data) {
    this.selectedOption = data.type;
    this.parentFormGroup.controls[this.controlName].setValue(this.selectedOption);
  }

  // Do not show field as touched and having error when clicked outside the field
  @HostListener('focusout', ['$event'])
  onBlur(event: Event) {
    if (!this.error) {
      this.parentFormGroup.controls[this.controlName].markAsUntouched();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const control = this.parentFormGroup.controls[this.controlName];
    this.isDisabled = control.status === 'DISABLED';
    this.fieldSubscription = control.valueChanges.subscribe((value) => {
      // If field is marked as touched, mark it as untouched
      if (this.parentFormGroup.controls[this.controlName].touched) {
        this.parentFormGroup.controls[this.controlName].markAsUntouched();
      }

      // Get errors on the form field
      const errors = this.parentFormGroup.controls[this.controlName].errors;

      // If errors exist, handle it
      if (errors) {
        // Clear errors on the form field
        this.parentFormGroup.controls[this.controlName].setErrors(null);

        // Clear out the error messages on the screen (parent component)
        this.checkForErrors.emit(this.controlName);

        // Add errors again to the form field for showing messages in the future
        this.parentFormGroup.controls[this.controlName].setErrors(errors);
      } else {
        // Clear out the error messages on the screen (parent component)
        this.checkForErrors.emit(this.controlName);
      }
      this.selectedOption = value;
      this.cdRef.detectChanges();
    });

    // Check if field is required
    if (control.validator) {
      const validator = control.validator({} as AbstractControl);
      this.isRequired = !!(validator && validator['required']);
    }

  }

  ngOnDestroy() {
    this.fieldSubscription?.unsubscribe();
  }
}


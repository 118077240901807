import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AppErrorStateMatcher, AuthService } from 'src/app/shared/services';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { CommonService } from 'src/app/shared/services/common.service';
import validator from 'validator';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public loading = 0;

  public email: string;
  public phone: string;
  public magicLinkMsg: string;
  public magicError: string;
  public magicCode: string;
  public magicTTL: number;

  public checkUserMessage: string;

  public matcher = new AppErrorStateMatcher();

  public checkUserForm: UntypedFormGroup = this.fb.group({
    username: ['', [Validators.required]],
  });
  public loginForm: UntypedFormGroup = this.fb.group({
    password: ['', [Validators.required]],
  });
  public phoneForm: UntypedFormGroup = this.fb.group({
    otp: ['', [Validators.required]],
  });

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    if (this.route.snapshot.queryParamMap.has('m')) {
      this.magicCode = this.route.snapshot.queryParamMap.get('m');
      this.magicTTL = Number(this.route.snapshot.queryParamMap.get('ttl')); // use it to show magic count down
      this.loginWithMagicCode(this.magicCode);
    }
    this.route.fragment.subscribe((fragment) => {
      const params = new URLSearchParams(fragment || '');
      const code = params.get('code');
      if (code) {
        this.loading++;
        this.loginWithAzureSSO(code);
      }
    });
  }

  loginWithAzureSSO(code: string){
    this.loading++;
    this.authService
      .loginWithAzureSSO(code)
      .toPromise()
      .then((resp: any) => {
        this.commonService.notification('Login successful', 'success');
        this.router.navigate([resp.redirectUrl]);
      })
      .catch((resp: HttpErrorResponse) => {
        if (resp.error.error) {
          this.checkUserForm.controls.username.setErrors({ error: resp.error.error });
        }
      })
      .finally(() => {
        this.loading--;
      });
  }

  ssoLoginClick(){
    window.location.href = this.authService.initializeLoginFlow();
  }

  /**
   * checks if user exists in system
   */
  checkUser() {
    if (this.checkUserForm.status === 'INVALID') {
      return;
    }

    let { username } = this.checkUserForm.value;
    username = username.trim();
    const isEmail = validator.isEmail(username);
    const email = isEmail ? username : null; // email = "example@gmail.com"
    const phone = !isEmail ? username : null; // phone = null

    this.loading++;
    this.authService
      .checkUser(email, phone)
      .then(() => {
        this.email = isEmail ? username : null;
        this.phone = !isEmail ? username : null;

        if (this.phone) {
          this.requestOTP(this.phone);
        }
      })
      .catch((resp: HttpErrorResponse) => {
        if (resp.status === 422) {
          this.matcher.setServerErrors(this.checkUserForm, resp, { email: 'username', phone: 'username' });
          return;
        }
        if (resp?.error?.error) {
          this.checkUserForm.controls.username.setErrors({ error: resp.error.error });
          this.checkUserMessage = resp.error.error;
        }
      })
      .finally(() => {
        this.loading--;
      });
  }

  /**
   * Login with password input
   */
  loginWithPassword() {
    if (this.loginForm.status === 'INVALID') {
      return;
    }

    const { password } = this.loginForm.value;
    this.loading++;
    this.authService
      .login(this.email, password)
      .toPromise()
      .then((resp: any) => {
        this.commonService.notification('Login successful', 'success');
        this.router.navigate([resp.redirectUrl]);
      })
      .catch((resp: HttpErrorResponse) => {
        if (resp.status === 422) {
          this.matcher.setServerErrors(this.loginForm, resp);
          return;
        }

        if (resp.status === 401) {
          this.loginForm.controls.password.setErrors({ error: 'Please check your login credentials.' });
          return;
        }

        if (resp.error.error) {
          // this.snackBar.open(resp.error.error, '', { duration: 5000 });
          this.commonService.notification(resp.error.error, 'error');
        }
      })
      .finally(() => {
        this.loading--;
      });
  }

  /**
   * Request OTP
   * @param phone phone number
   */
  requestOTP(phone) {
    this.loading++;
    this.authService
      .requestOTP(phone)
      .then((resp: any) => {
        if (resp.message) {
          // this.snackBar.open(resp.message, 'Close');
          this.commonService.notification(resp.message, '', 'Close');
        }
      })
      .catch((resp: HttpErrorResponse) => {
        if (resp.error.error) {
          // this.snackBar.open(resp.error.error, '', { duration: 5000 });
          this.commonService.notification(resp.error.error, 'error');
        }
      })
      .finally(() => {
        this.loading--;
      });
  }

  /**
   * Login with phone otp
   */
  loginWithPhone() {
    if (this.phoneForm.status === 'INVALID') {
      return;
    }

    const { otp } = this.phoneForm.value;
    this.loading++;
    this.authService
      .loginWithOTP(this.phone, otp)
      .then((resp: any) => {
        this.commonService.notification('Login successful', 'success');
        this.router.navigate([resp.redirectUrl]);
      })
      .catch((resp: HttpErrorResponse) => {
        if (resp.status === 422) {
          this.matcher.setServerErrors(this.phoneForm, resp);
          return;
        }

        if (resp.status === 401) {
          this.phoneForm.controls.otp.setErrors({ error: 'Invalid OTP' });
          return;
        }

        if (resp.error.error) {
          this.commonService.notification(resp.error.error, 'error');
        }
      })
      .finally(() => {
        this.loading--;
      });
  }

  /**
   * Request magic link to given email
   */
  getMagicLink() {
    this.loading++;
    this.authService
      .getMagicLink(this.email)
      .then((resp: any) => {
        if (resp.message) {
          this.magicLinkMsg = resp.message;
        }
      })
      .catch((resp: HttpErrorResponse) => {
        if (resp.error.error) {
          this.commonService.notification(resp.error.error, 'error');
        }
      })
      .finally(() => {
        this.loading--;
      });
  }

  /**
   * Attempt to login with magic link
   * @param code magic link code
   */
  loginWithMagicCode(code) {
    this.loading++;
    this.authService
      .loginWithMagicCode(code)
      .then((resp: any) => {
        this.commonService.notification('Login successful', 'success');
        this.router.navigate([resp.redirectUrl]);
      })
      .catch((resp: HttpErrorResponse) => {
        if (resp.error.error) {
          this.magicError = resp.error.error;
          return;
        }

        this.commonService.notification('Unable to login with magic link', 'danger');
      })
      .finally(() => {
        this.loading--;
      });
  }

  /**
   * Reset login params
   */
  resetLogin() {
    this.email = null;
    this.phone = null;
    this.magicCode = null;
    this.magicError = null;
    this.magicLinkMsg = null;
    this.router.navigate(['/login']);
  }
}
